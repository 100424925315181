import { defineMessages } from 'react-intl';

export default defineMessages({
    VRNMissing: {
        id: 'quoteandbind.views.VRN-Missing.VRN-Missing',
        defaultMessage: 'VRN-Missing',
    },
    VRNMissingWarning: {
        id: 'quoteandbind.views.VRN-Missing.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    VRNMissingSolution: {
        id: 'quoteandbind.views.VRN-Missing.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.VRN-Missing.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.VRN-Missing.Reference Number',
        defaultMessage: 'Reference Number',
    },
    getAssistanceTextTYA: {
        id: 'quoteandbind.views.get assistance text tya',
        defaultMessage: 'We are experiencing some technical problems. Please try again later or call us on 0330 022 9450. We apologise for any inconvenience caused.',
    },
    getAssistanceTextKFI: {
        id: 'quoteandbind.views.get assistance text kfi',
        defaultMessage: 'Unfortunately we\'re experiencing some technical problems, but we are still here to help you.',
    },
    getAssistanceText2: {
        id: 'quoteandbind.views.ErrorPage.contact our customer service team text two',
        defaultMessage: 'If you contact our Customer Service team on <span-contact-highlight>0333 103 9670</span-contact-highlight> they will be able to continue where you left off.',
    },
    getAssistanceTimings2: {
        id: 'quoteandbind.views.ErrorPage.contact our customer service team timmings2',
        defaultMessage: '<span-contact-highlight>Monday - Friday</span-contact-highlight> 08:30-18:00',
    },
    getAssistanceTimings3: {
        id: 'quoteandbind.views.ErrorPage.contact our customer service team timmings3',
        defaultMessage: '<span-contact-highlight>Saturday</span-contact-highlight> 09:00-13:00',
    },
    getAssistanceTimings4: {
        id: 'quoteandbind.views.ErrorPage.contact our customer service team timmings4',
        defaultMessage: '<span-contact-highlight>Bank Holidays</span-contact-highlight> 08:00-16:00',
    },
});
