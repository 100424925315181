import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _excluded = ["id", "src", "height", "title", "aspectRatio", "className", "loaderClassName", "onLoad", "showLoader", "noBorder"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useState } from "react";
import { warning } from "@jutro/logger";
import { useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./IFrame.module.css";
import { Loader } from "../loading/loader/Loader";
var aspectRatios = ["4/3", "16/9", "17/9", "2/1"],
  aspectRatioStyle = _reduceInstanceProperty(aspectRatios).call(aspectRatios, function (reduced, ratio) {
    var suffix = ratio.replace("/", "x");
    return _objectSpread(_objectSpread({}, reduced), {}, _defineProperty({}, ratio, "aspect-ratio-".concat(suffix)));
  }, {}),
  validateHeight = function (height) {
    var isValid = /^[0-9]+(.[0-9]+)?(px|%)$/.test(height);
    return isValid || warning("Height prop : ".concat(height, " is not valid. Please provide a number suffixed with px or %")), isValid;
  },
  iframePropTypes = {
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    title: intlMessageShape.isRequired,
    aspectRatio: PropTypes.oneOf(["4/3", "16/9", "17/9", "2/1"]),
    height: PropTypes.string,
    className: PropTypes.string,
    loaderClassName: PropTypes.string,
    onLoad: PropTypes.func,
    showLoader: PropTypes.bool,
    noBorder: PropTypes.bool
  };
export var IFrame = function IFrame(_ref) {
  var id = _ref.id,
    src = _ref.src,
    height = _ref.height,
    title = _ref.title,
    aspectRatio = _ref.aspectRatio,
    className = _ref.className,
    loaderClassName = _ref.loaderClassName,
    onLoad = _ref.onLoad,
    showLoader = _ref.showLoader,
    noBorder = _ref.noBorder,
    rest = _objectWithoutProperties(_ref, _excluded),
    translator = useTranslator(),
    _useState = useState(showLoader),
    _useState2 = _slicedToArray(_useState, 2),
    iframeLoading = _useState2[0],
    setIframeLoading = _useState2[1],
    _useState3 = useState(src),
    _useState4 = _slicedToArray(_useState3, 2),
    prevSrc = _useState4[0],
    setPrevSrc = _useState4[1];
  src !== prevSrc && (setPrevSrc(src), showLoader && setIframeLoading(!0));
  var _IFrame$defaultProps,
    aspectRatioClass = aspectRatio && aspectRatioStyle[aspectRatio] ? styles[aspectRatioStyle[aspectRatio]] : void 0,
    containerClasses = cx(styles.iframeContainer, aspectRatioClass),
    iframeClasses = cx(styles.iframe, _defineProperty({}, styles.noBorder, noBorder), className),
    frameContainerStyle = {};
  aspectRatio && _includesInstanceProperty(aspectRatios).call(aspectRatios, aspectRatio) || (frameContainerStyle = {
    paddingBottom: height && validateHeight(height) ? height : null === (_IFrame$defaultProps = IFrame.defaultProps) || void 0 === _IFrame$defaultProps ? void 0 : _IFrame$defaultProps.height
  });
  return React.createElement("div", {
    className: containerClasses,
    style: frameContainerStyle
  }, iframeLoading && React.createElement(Loader, {
    className: cx(styles.loader, loaderClassName)
  }), React.createElement("iframe", _extends({
    id: id,
    src: src,
    title: translator(title),
    onLoad: function () {
      onLoad && onLoad(), showLoader && setIframeLoading(!1);
    },
    className: iframeClasses
  }, rest)));
};
IFrame.propTypes = iframePropTypes, IFrame.defaultProps = {
  height: "50%",
  showLoader: !0
}, IFrame.__docgenInfo = {
  description: "IFrame\n@metadataType container",
  methods: [],
  displayName: "IFrame",
  props: {
    height: {
      defaultValue: {
        value: "'50%'",
        computed: !1
      },
      type: {
        name: "string"
      },
      required: !1,
      description: "Height for the iframe container in pixel or percentage format"
    },
    showLoader: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show loader when iframe still loading"
    },
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "A unique id of the iframe container"
    },
    src: {
      type: {
        name: "string"
      },
      required: !0,
      description: "`src` for the iframe"
    },
    title: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Title for the iframe"
    },
    aspectRatio: {
      type: {
        name: "enum",
        value: [{
          value: "'4/3'",
          computed: !1
        }, {
          value: "'16/9'",
          computed: !1
        }, {
          value: "'17/9'",
          computed: !1
        }, {
          value: "'2/1'",
          computed: !1
        }]
      },
      required: !1,
      description: "`aspectRatio` for the iframe"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "`className` for the iframe"
    },
    loaderClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "`className` for the loader"
    },
    onLoad: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for the onload event"
    },
    noBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide the iframe border"
    }
  }
}, IFrame.__docgenInfo = {
  componentName: "IFrame",
  packageName: "@jutro/components",
  description: "IFrame",
  displayName: "IFrame",
  methods: [],
  actualName: "IFrame",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: !0,
      description: "A unique id of the iframe container"
    },
    src: {
      type: {
        name: "string"
      },
      required: !0,
      description: "`src` for the iframe"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Title for the iframe"
    },
    aspectRatio: {
      type: {
        name: "enum",
        value: [{
          value: "'4/3'",
          computed: !1
        }, {
          value: "'16/9'",
          computed: !1
        }, {
          value: "'17/9'",
          computed: !1
        }, {
          value: "'2/1'",
          computed: !1
        }]
      },
      required: !1,
      description: "`aspectRatio` for the iframe"
    },
    height: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Height for the iframe container in pixel or percentage format",
      defaultValue: {
        value: "'50%'",
        computed: !1
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "`className` for the iframe"
    },
    loaderClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "`className` for the loader"
    },
    onLoad: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Function for the onload event"
    },
    showLoader: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Show loader when iframe still loading",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    noBorder: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide the iframe border"
    }
  }
};