import { defineMessages } from 'react-intl';

export default defineMessages({
    DeclinedCue: {
        id: 'quoteandbind.views.declined-cue.Declined Cue',
        defaultMessage: 'Declined Cue',
    },
    DeclinedCueWarning: {
        id: 'quoteandbind.views.declined-cue.Sorry, we were not able to complete your quote online.',
        defaultMessage: 'Sorry, we were not able to complete your quote online.',
    },
    DeclinedCueSolution: {
        id: 'quoteandbind.views.declined-cue.Please contact us to discuss your quote over the phone.',
        defaultMessage: 'Please contact us to discuss your quote over the phone.',
    },
    getAssistance: {
        id: 'quoteandbind.views.declined-cue.Please contact us with the reference number below and we would be pleased to assist you.',
        defaultMessage: 'Please contact us with the reference number below and we would be pleased to assist you.',
    },
    quoteID: {
        id: 'quoteandbind.views.declined-cue.Reference Number',
        defaultMessage: 'Reference Number',
    },
    DeclinedCueInformationTextTYA: {
        id: 'quoteandbind.views.declined-cue.contact information tya',
        defaultMessage: 'If you think the information held is incorrect, contact us on 0330 022 9451.',
    },
    DeclinedCueInformationTextKFI: {
        id: 'quoteandbind.views.declined-cue.contact information kfi',
        defaultMessage: 'If you think the information held is incorrect, contact us on <span-contact-highlight>0330 022 6868</span-contact-highlight>.',
    },
});
