import "core-js/modules/es.regexp.exec";
import { isIntlToShapeLocation } from "@jutro/prop-types";
import { sanitizeUrl } from "@jutro/platform";
import { useTranslator } from "../TranslatorProvider";
export var getUrlTranslatorAndSanitizer = function (translator) {
  return function (url) {
    var allowNoLeadingSlash = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return isIntlToShapeLocation(url) ? {
      pathname: sanitizeUrl(translator(url.pathname), allowNoLeadingSlash),
      search: translator(url.search),
      hash: translator(url.hash),
      state: url.state
    } : sanitizeUrl(translator(url), allowNoLeadingSlash);
  };
};
export var useSafeTranslatedUrls = function () {
  var translator = useTranslator();
  return getUrlTranslatorAndSanitizer(translator);
};