import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
    useMemo
} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory
} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides, MetadataContent } from '@jutro/uiconfig';
import { Main, useModal, ModalNextEmitter } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { routeConfirmationModal } from '@jutro/router';
import htmlParser from 'html-react-parser';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import vmTranslator, { messages as platformMessages } from 'gw-platform-translations';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { brandingData, setBranding } from 'gw-portals-branding-js';
import { RoutingTracking } from 'gw-portals-tracking-react';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';

import 'gw-capability-quoteandbind-react';
import { commonMessages, QuoteRetrievalPage, AggRetrievalPage, AMPQuoteEntryPage } from 'gw-capability-quoteandbind-common-react';
import {
    segmentationMap,
    segmentationComponentMap,
    Segmentation,
    SegmentationOption
} from 'gw-portals-segmentation-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { VehicleInfoLookupComponentMap, VehicleInfoLookupComponents } from 'gw-capability-vehicleinfo-react';
import { GooglePlacesLookupComponentMap, GooglePlacesLookupComponents } from 'gw-capability-googleplaces-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { LoadSaveService, CustomQuoteService, UserProfileService } from 'gw-capability-quoteandbind';
import { PropertyCodeLookupService } from 'gw-capability-propertycode';
import CoverageUtil from 'gw-capability-quoteandbind-pm-react/util/CoverageUtil';
import BrandIconUtil from 'gw-capability-quoteandbind-common-react/util/BrandIconUtil';

import { GTPWizard } from 'gw-capability-quoteandbind-gtp-react';
import { BOPWizard } from 'gw-capability-quoteandbind-bop-react';
import { HOWizard } from 'gw-capability-quoteandbind-ho-react';
import { PAWizard } from 'gw-capability-quoteandbind-pa-react';
import { PMWizard } from 'gw-capability-quoteandbind-pm-react';
import { SLQWizard } from 'gw-capability-quoteandbind-slq-react';
import FaqPage from 'gw-capability-faq-react';

import LandingPage from '../pages/LandingPage/LandingPage';
import CustomLandingPage from '../pages/CustomLandingPage/CustomLandingPage';
import ContactUs from '../pages/ContactUs/ContactUs';
import PurchaseFailed from '../pages/PurchaseFailed/PurchaseFailed';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import SessionTimeout from '../pages/SessionTimeout/SessionTimeout';
import SessionEnded from '../pages/SessionEnded/SessionEnded';
import Declined from '../pages/Declined/Declined';
import DeclinedUW from '../pages/DeclinedUW/DeclinedUW';
import DeclinedCue from '../pages/DeclinedCue/DeclinedCue';
import VRNMissing from '../pages/VRNMissing/VRNMissing';

import metadata from './App.metadata.json5';
import './App.messages';

import { SessionService } from "../services/SessionService/SessionService";

// Importing the script library for the Live Chat Feature
const script = document.createElement('script');
script.src = 'https://apps.commbox.io//Scripts/connect.js';
document.head.appendChild(script);
// .. End of Live Chat Feature script import

setComponentMapOverrides({
    ...platformComponentMap,
    ...AddressLookupComponentMap,
    ...VehicleInfoLookupComponentMap,
    ...GooglePlacesLookupComponentMap,
    ...segmentationMap,
    ...policyJobComponentMap,
    // replace the native IMG component with a proxied version
    img: { component: 'img' }
}, {
    ...platformComponents,
    ...AddressLookupComponents,
    ...VehicleInfoLookupComponents,
    ...GooglePlacesLookupComponents,
    ...segmentationComponentMap,
    ...policyJobComponents,
    img: ImageComponent
});

const modalEmitter = new ModalNextEmitter();

const TALKDESK_TOUCHPOINT_ID = {
    'tya': '3774b14d5b8a4ea9a04c0c0e29db6f5f',
    'kfi': '2c50759bc07644d5addce480fc0538f0'
};

export default function App() {
    const {
        showAlert
    } = useModal();

    const history = useHistory();

    const localeService = ServiceManager.getService('locale-service');
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);
    const [showContactUs, setShowContactUs] = useState(false);
    const [showAMPBtn, setShowAMPBtn] = useState(false);
    const brand = brandingData.BRANDING;
    const googleAnalyticsMappings = { kfi: 'GTM-N85GHZF', tya: 'GTM-5WRXM88' };
    let webchat;

    const toggleContactUsSection = useCallback(() => {
        setShowContactUs(!showContactUs);
    }, [showContactUs]);

    const shouldToggleContactUsSection = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            toggleContactUsSection();
        }
    }, [phoneMenuRef, toggleContactUsSection]);

    const clickOnPhoneNumber = useCallback((event) => {
        event.preventDefault();
        window.location = `tel:${appConfig.phoneNumber}`;
    }, []);


    useEffect(() => {
          (function(window, document, node, props, configs) {
            var divContainer = document.createElement("div");
            divContainer.id = node;
            document.body.appendChild(divContainer);
            var src = "https://talkdeskchatsdk.talkdeskapp.com/v2/talkdeskchatsdk.js";
            var script = document.createElement("script");
            var firstScriptTag = document.getElementsByTagName("script")[0];
            script.type = "text/javascript";
            script.charset = "UTF-8";
            script.id = "tdwebchatscript";
            script.src = src;
            script.async = true;
            firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
            script.onload = function() {
              webchat = TalkdeskChatSDK(node, props);
              webchat.init(configs);
               function setContext() {
                 webchat.setContextParam({ "brand": brandingData.BRANDING })
               }
               // Send data when the chat conversation is initiated
               webchat.onConversationStart = function() {
                 setContext()
               }
               // Send data when the chat widget is open
               webchat.onOpenWebchat = function() {
                 setContext()
               }
            };
          })(
            window,
            document,
            "tdWebchat",
            { touchpointId: TALKDESK_TOUCHPOINT_ID[brandingData.BRANDING], accountId: "", region: "td-eu-1" },
            { enableValidation: false, enableEmoji: true, enableUserInput: true, enableAttachments: true }
          );
    }, []);

    useEffect(() => {
        const entry = performance.getEntriesByType("navigation")[0];
        const ampRedirectBln = sessionStorage.getItem('ampRedirectBln') === 'true';
        if (entry && ampRedirectBln) {
            const reload = entry.type === 'reload';
            if (reload) {
                history.push('/');
                sessionStorage.clear();
            }
        }
    }, []);

    useEffect(() => {
        if (window.location.href.includes('ampquoteentry')) {
            history.replace({ state: { previousLocation: history.location } });
            setShowAMPBtn(true);
            history.push('/amp-quote-entry', { previousLocation: history.location });
        }
        if (window.location.pathname.startsWith('/aggs')) {
            
            let pathSplit = window.location.pathname.split('/');

            let aggCode = pathSplit[2];

            window.history.replaceState({}, document.title, "quoteandbuy/agg-retrieval?agg=" + aggCode);
        }
        if (window.location.hash.includes('#')) {
            let routeWithoutHash = window.location.hash;
            if(!window.location.href.includes('quoteandbuy')) {
                if(window.location.hash.startsWith('#/')) {
                    routeWithoutHash = window.location.hash.replace('#/','quoteandbuy/');
                }
            }
            routeWithoutHash = routeWithoutHash.replaceAll('#','');
            let pathname = window.location.pathname;
            if(window.location.pathname.substring(window.location.pathname.length-1) === '/') {
                pathname = window.location.pathname.substring(0, window.location.pathname.length-1);
            }

            window.history.replaceState({}, document.title, pathname + routeWithoutHash);
        }
        const root = document.querySelector(':root');
        root.style.setProperty('--and-brand-header-logo-url', `url(${BrandIconUtil.getBrandIconUrl()})`);
        const link = document.createElement('link');
        const oldLink = document.getElementById('and-dynamic-favicon');
        link.id = 'and-dynamic-favicon';
        link.rel = 'icon';
        link.href = `./quoteandbuy/app/html/favicons/favicon-${brandingData.BRANDING}.ico`;
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }, [location]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(
                    result,
                    translatorFn
                )
            );
        });

        if (brand) {
            setBranding();
        }

        CoverageUtil.resetCoverageSelectedValue(brand);

        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            // a listener so we to the phone menu so we can
            // close the menu if there's a click outside the menu
            document.addEventListener('mousedown', shouldToggleContactUsSection, false);
            // If a function is returned from a useEffect,
            // it will be used to clean up when component is unmounted
            return () => document.removeEventListener('mousedown', shouldToggleContactUsSection, false);
        }
    }, [breakpoint, shouldToggleContactUsSection, toggleContactUsSection, translator]);

    const ampReturnClick = useCallback(async () => {
        await LoadSaveService.getPortalDomain_AND(brand)
            .then((url) => {
                window.location.href = url;
            });
    }, []);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    const headerMetadata = metadata.pageContent.content.filter((item) => item.id === 'header');

    const metadataToRender = useMemo(() => {
        if (breakpoint === 'phone' || breakpoint === 'phoneWide') {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneHeader');
        }
        //return metadata.pageContent.content.filter((item) => item.id === 'desktopHeader');
        return metadata.pageContent.content.filter((item) => item.id === 'header');
    }, [breakpoint]);

    const handleError = useCallback((error = {}) => {
        const state = {
            pathname: '/error',
            state: error,
            origin: "App",
            quoteID: ''
        };

        return () => <Redirect to={state} />
        
    }, []);


    // GA code

    if (!document.getElementById('gaScriptID')) {
        const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer', '${googleAnalyticsMappings[brand]}');`;
        const gtmURL = `<iframe src='https://www.googletagmanager.com/ns.html?id=${googleAnalyticsMappings[brand]} height='0' width='0' style='display:none;visibility:hidden'></iframe>`;
        const gaScript = document.createElement('script');
        gaScript.id = 'gaScriptID';
        gaScript.append(gtm);
        document.head.appendChild(gaScript);
        const noscript = document.createElement('noscript');
        document.body.insertBefore(noscript, document.body.firstChild);
        noscript.append(gtmURL);
    }

    // tracking pixel scripts
    if (!document.getElementById('fbPixelScript')) {
        const FBPixelBrandId = '305124740145016';
        const fpt = `!function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '${FBPixelBrandId}');
                        fbq('track', 'PageView');
                        `;
        const ftpURL = `<iframe src='https://www.facebook.com/tr?id=${FBPixelBrandId}&ev=PageView&noscript=1'></iframe>`;
        const FBPixelScript = document.createElement('script');
        FBPixelScript.id = 'fbPixelScript';
        FBPixelScript.append(fpt);
        document.head.appendChild(FBPixelScript);
        const noFBScript = document.createElement('noscript');
        document.body.insertBefore(noFBScript, document.body.firstChild);
        noFBScript.append(ftpURL);
    }

    if (!document.getElementById('pinPixelScript')) {
        const PINPixelBrandId = '2613534347598';
        const ptp = `!function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
            n=window.pintrk;n.queue=[],n.version="3.0";var
            t=document.createElement("script");t.async=!0,t.src=e;var
            r=document.getElementsByTagName("script")[0];
            r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '${PINPixelBrandId}', {em: '<user_email_address>'});
            pintrk('page');
            `;
        const ptpURL = `<iframe src='https://ct.pinterest.com/v3/?event=init&tid=${PINPixelBrandId}&pd[em]=<hashed_email_address>&noscript=1'></iframe>`;
        const pinScript = document.createElement('script');
        pinScript.id = 'pinPixelScript';
        pinScript.append(ptp);
        document.head.appendChild(pinScript);
        const noPinScript = document.createElement('noscript');
        document.body.insertBefore(noPinScript, document.body.firstChild);
        noPinScript.append(ptpURL);
    }

    if (!document.getElementById('xScriptId')) {
        const XPixelBrandID = 'o5m8r';
        const ttp = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
        twq('init','${XPixelBrandID}');
        twq('track','PageView');
        `;
        const ttpURL = `<iframe src='https://analytics.twitter.com/i/adsct?txn_id=${XPixelBrandID}&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0'></iframe>`;
        const xScript = document.createElement('script');
        xScript.id = 'xScriptId';
        xScript.append(ttp);
        document.head.appendChild(xScript);
        const noXScript = document.createElement('noscript');
        document.body.insertBefore(noXScript, document.body.firstChild);
        noXScript.append(ttpURL);
    }

    // end of trackingpixel script

    const overrideProps = {
        globalizationChooserField: {
            onLanguageValueChange: localeService.saveLanguage,
            languageValue: localeService
                .getStoredLanguage()
        },
        mobilePhoneNumber: {
            content: appConfig.phoneNumber
        },
        contactUsSectionID: {
            visible: showContactUs
        },
        contactUsTime1: {
            content: htmlParser('<b>Mon - Fri:</b> 08:30 - 18:00'),
        },
        contactUsTime2: {
            content: htmlParser('<b>Sat:</b> 09:00 - 13:00'),
        },
        contactUsTime3: {
            content: htmlParser('<b>Sun:</b> Closed'),
        },
        contactUsTime4: {
            content: htmlParser('<b>Bank Holidays:</b> 08:00 - 16:00'),
        },
        contactUsLCTime1: {
            content: htmlParser('<b>Mon - Fri:</b> 08:00 - 19:45'),
        },
        contactUsLCTime2: {
            content: htmlParser('<b>Sat:</b> 09:00 - 16:00'),
        },
        contactUsLCTime3: {
            content: htmlParser('<b>Sun:</b> Closed'),
        },
        contactUsLCTime4: {
            content: htmlParser('<b>Bank Holidays:</b> 08:00 - 16:00'),
        },
        gwAMPVerticalAlignContainer: {
            visible: showAMPBtn
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            toggleContactUsSection: toggleContactUsSection,
            clickOnPhoneNumber: clickOnPhoneNumber,
            onAMPReturnClick: ampReturnClick,
        }
    };

    const footerMetadata = metadata.pageContent.content.filter((item) => item.id === 'footer');
    function talkDeskLauncherFunction(newState) {
        setTimeout(function () {
            let talkDeskButton = document.getElementById('talkdesk-chat-widget-trigger')
            talkDeskButton.classList.add("talkdeskButtonExta");
            
        }, 800);
    }
    //talkDeskLauncherFunction()
    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                LoadSaveService,
                CustomQuoteService,
                PropertyCodeLookupService,
                UserProfileService
            }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <Segmentation type="query-params">
                        <SegmentationOption defaultOption>
                            <Router
                                basename={(window.location.hostname === 'localhost') ? `?branding=${brand}/quoteandbuy` : '/quoteandbuy'}
                                getUserConfirmation={
                                    (message, callback) => routeConfirmationModal(
                                        message,
                                        callback,
                                        modalEmitter
                                    )
                                }
                            >
                                <SessionService/>
                                
                                <Main className="qnbMain" contentClassName="qnbMainContent" fluid>  
                                    <MetadataContent
                                        uiProps={metadataToRender}
                                        overrideProps={overrideProps}
                                        {...resolvers}
                                    />
                                    <RoutingTracking />
                                    <div className="qnbContent">
                                        <ErrorBoundary onError={handleError}>
                                            <Switch>
                                                <Route path="/contact-us" component={ContactUs} />
                                                <Route path="/error" component={ErrorPage} />
                                                <Route path="/purchase-failed" component={PurchaseFailed} />
                                                <Route path="/timeout" component={SessionTimeout} />
                                                <Route path="/session-ended" component={SessionEnded} />
                                                <Route path="/declined" component={Declined} />
                                                <Route path="/declined-uw" component={DeclinedUW} />
                                                <Route path="/declined-cue" component={DeclinedCue} />
                                                <Route path="/quote-retrieval" component={QuoteRetrievalPage} />
                                                <Route path="/agg-retrieval" component={AggRetrievalPage} />
                                                <Route path="/amp-quote-entry" component={AMPQuoteEntryPage} />
                                                <Route path="/error-missing-data" component={VRNMissing} />
                                                <Route path="/" component={PMWizard} />
                                                <Route render={() => <Redirect to="/" />} />
                                            </Switch>
                                        </ErrorBoundary>
                                    </div>
                                    <MetadataContent uiProps={footerMetadata} />
                                </Main>
                            </Router>
                        </SegmentationOption>
                        <SegmentationOption is={[{ LP: '1' }, { LP: '2' }]}>
                            <CustomLandingPage />
                        </SegmentationOption>
                    </Segmentation>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}
