import "core-js/modules/es.regexp.exec";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useCallback } from "react";
import { Route } from "react-router-dom";
import { getConfigValue } from "@jutro/config";
import { warning } from "@jutro/logger";
import { PathnameSwitch, joinUrl } from "@jutro/platform";
import { AuthContextProvider } from "./AuthContextProvider";
import { AuthStateProvider } from "./AuthStateProvider";
import { LoginCallback } from "./LoginCallback";
export var OPENID_SCOPE = "openid";
export var DEFAULT_EXPIRE_EARLY_SECONDS = 30;
export var AUTO_RENEW = !0;
export var DEFAULT_STORAGE = "localStorage";
var getAuthStorageValue = function () {
  var storage = getConfigValue("JUTRO_AUTH_STORAGE", DEFAULT_STORAGE);
  return "cookie" === storage && (warning("cookie storage is not supported. Storage will fall to default ".concat(DEFAULT_STORAGE)), storage = DEFAULT_STORAGE), storage;
};
export var AuthProvider = function (_ref) {
  var children = _ref.children,
    authErrorComponent = _ref.authErrorComponent,
    noService = _ref.noService,
    renderLogin = useCallback(function () {
      return React.createElement(LoginCallback, {
        errorComponent: authErrorComponent
      });
    }, [authErrorComponent]),
    authConfig = function () {
      var expireEarlySeconds,
        autoRenew,
        scopes = getConfigValue("JUTRO_AUTH_SCOPE", "".concat(OPENID_SCOPE, ",profile,email")).split(",");
      return -1 === _indexOfInstanceProperty(scopes).call(scopes, OPENID_SCOPE) && scopes.push(OPENID_SCOPE), {
        isEnabled: getConfigValue("JUTRO_AUTH_ENABLED", !1),
        issuer: getConfigValue("JUTRO_AUTH_ISSUER", ""),
        clientId: getConfigValue("JUTRO_AUTH_CLIENT_ID", ""),
        redirectBase: joinUrl(window.location.origin, getConfigValue("JUTRO_ROUTER_BASENAME", "")),
        redirectPath: getConfigValue("JUTRO_AUTH_REDIRECT_PATH", ""),
        scopes: scopes,
        pkce: getConfigValue("JUTRO_AUTH_PKCE_ENABLED", !1),
        tokenManager: (expireEarlySeconds = getConfigValue("JUTRO_AUTH_EXPIRE_EARLY_SECONDS", DEFAULT_EXPIRE_EARLY_SECONDS), autoRenew = getConfigValue("JUTRO_AUTH_AUTO_RENEW", AUTO_RENEW), {
          expireEarlySeconds: expireEarlySeconds,
          storage: getAuthStorageValue(),
          autoRenew: autoRenew,
          autoRemove: autoRenew
        }),
        storageManager: {
          token: {
            storageType: getAuthStorageValue(),
            storageTypes: ["localStorage", "sessionStorage", "memory"]
          }
        }
      };
    }();
  if (authConfig.isEnabled) {
    var _context,
      issuer = authConfig.issuer,
      clientId = authConfig.clientId,
      redirectBase = authConfig.redirectBase,
      redirectPath = authConfig.redirectPath,
      scopes = authConfig.scopes,
      pkce = authConfig.pkce,
      tokenManager = authConfig.tokenManager,
      redirectUri = _concatInstanceProperty(_context = "".concat(redirectBase)).call(_context, redirectPath);
    return React.createElement(AuthStateProvider, {
      noService: noService,
      issuer: issuer,
      clientId: clientId,
      redirectUri: redirectUri,
      scopes: scopes,
      pkce: pkce,
      tokenManager: tokenManager
    }, React.createElement(AuthContextProvider, null, React.createElement(Route, {
      exact: !0,
      path: redirectPath,
      render: renderLogin
    }), React.createElement(PathnameSwitch, null, children)));
  }
  return React.createElement(React.Fragment, null, children);
};
AuthProvider.__docgenInfo = {
  description: "A HOC that wraps children components in Okta Security. This will initialize the\nokta configuration and set up a callback route to handle the login redirect.\nWrapping will only happen if authentication is enabled, otherwise the original\ncomponent will be returned, unwrapped.",
  methods: [],
  displayName: "AuthProvider"
}, AuthProvider.__docgenInfo = {
  componentName: "AuthProvider",
  packageName: "@jutro/auth",
  description: "A HOC that wraps children components in Okta Security. This will initialize the\nokta configuration and set up a callback route to handle the login redirect.\nWrapping will only happen if authentication is enabled, otherwise the original\ncomponent will be returned, unwrapped.",
  displayName: "AuthProvider",
  methods: [],
  actualName: "AuthProvider"
};