import "core-js/modules/es.regexp.exec";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context = Object.prototype.toString.call(o)).call(_context, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
var formatChars = {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]"
  },
  parseMask = function (mask) {
    var _step,
      escape = !1,
      parsedMask = "",
      patternMap = [],
      _iterator = _createForOfIteratorHelper(mask);
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var char = _step.value;
        "\\" === char ? (escape = !escape) || (parsedMask += char, patternMap.push(null)) : (parsedMask += formatChars[char] && !escape ? "_" : char, patternMap.push(escape ? null : formatChars[char]), escape = !1);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return {
      parsedMask: parsedMask,
      patternMap: patternMap
    };
  };
export var isComplete = function (value) {
  var mask = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "_",
    _parseMask = parseMask(mask),
    parsedMask = _parseMask.parsedMask,
    patternMap = _parseMask.patternMap;
  return !(value.length < parsedMask.length) && value.split("").every(function (char, index) {
    var pattern = patternMap[index];
    return !pattern || char.match(pattern);
  });
};