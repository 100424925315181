import "core-js/modules/es.regexp.exec";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _WeakMap from "@babel/runtime-corejs3/core-js-stable/weak-map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { useRef, useLayoutEffect } from "react";
export var ApplyElementClass = function (_ref) {
  return function (_ref2) {
    var element = _ref2.element,
      className = _ref2.className,
      isUnmounting = useRef(!1),
      previousRef = useRef({
        updateTime: 0,
        className: ""
      });
    className !== previousRef.current.className && (reconcileElementClass(element, previousRef.current.className, className), previousRef.current = {
      updateTime: Date.now() + counter,
      className: className
    }, counter += 1, elementStatusRegistry.set(element, previousRef.current));
    useLayoutEffect(function () {
      return function () {
        isUnmounting.current = !0;
      };
    }, []), useLayoutEffect(function () {
      return function () {
        if (isUnmounting.current) {
          var lastUpdateElementStatus = elementStatusRegistry.get(element);
          if (lastUpdateElementStatus && (lastUpdateElementStatus.className !== className || lastUpdateElementStatus.updateTime > previousRef.current.updateTime)) {
            var oldClasses = className.split(" "),
              newClasses = lastUpdateElementStatus.className.split(" ");
            return void reconcileElementClass(element, _filterInstanceProperty(oldClasses).call(oldClasses, function (oldClass) {
              return !_includesInstanceProperty(newClasses).call(newClasses, oldClass);
            }).join(" "));
          }
          reconcileElementClass(element, className), elementStatusRegistry.delete(element);
        }
      };
    }, [element, className]);
  }({
    element: _ref.element,
    className: _ref.className
  }), null;
};
function reconcileElementClass(element, prevClass, newClass) {
  var _element$classList, _element$classList2;
  prevClass && (_element$classList = element.classList).remove.apply(_element$classList, _toConsumableArray(prevClass.split(" ")));
  newClass && (_element$classList2 = element.classList).add.apply(_element$classList2, _toConsumableArray(newClass.split(" ")));
}
var elementStatusRegistry = new _WeakMap(),
  counter = 0;