import "core-js/modules/es.regexp.exec";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import last from "lodash/last";
import { NavLink } from "react-router-dom";
import { useSafeTranslatedUrls, useTranslator } from "@jutro/locale";
import { intlMessageShape } from "@jutro/prop-types";
import { Icon, Link } from "@jutro/components";
import { useAccessibleRef, usePathname } from "@jutro/platform";
import { NavigationContext } from "./NavigationContext";
import { renderBadge } from "./SideNavigation/renderBadge";
import styles from "./NavBarItem.module.css";
export var getIdFromPath = function (path) {
  var _context,
    _context2,
    pathArr = _filterInstanceProperty(_context = path.split("/")).call(_context, Boolean),
    parentPath = pathArr[0],
    childPath = last(pathArr);
  return parentPath === childPath ? childPath : _concatInstanceProperty(_context2 = "".concat(parentPath, "__")).call(_context2, childPath);
};
export var NavBarLinkInternal = function (_ref) {
  var _cx,
    header = _ref.header,
    toProp = _ref.to,
    href = _ref.href,
    exact = _ref.exact,
    icon = _ref.icon,
    disabled = _ref.disabled,
    className = _ref.className,
    activeClassName = _ref.activeClassName,
    alignment = _ref.alignment,
    notifications = _ref.notifications,
    setActiveRoute = useContext(NavigationContext).setActiveRoute,
    translator = useTranslator(),
    urlTranslatorAndSanitizer = useSafeTranslatedUrls(),
    linkRef = useAccessibleRef(),
    to = urlTranslatorAndSanitizer(toProp),
    isTopNavBar = "top" === alignment,
    pathname = usePathname(),
    isActiveRoute = to === pathname,
    isActiveSideNavItem = isActiveRoute && !isTopNavBar,
    isInternalLink = !!to,
    classes = cx(styles.navBarItem, isTopNavBar ? styles.topNavBarItem : styles.leftNavBarItem, (_defineProperty(_cx = {}, styles.activeTopNavBarItem, isActiveRoute && isTopNavBar), _defineProperty(_cx, styles.activeLeftNavBarItem, isActiveSideNavItem), _defineProperty(_cx, "isActiveSideNavItem", isActiveSideNavItem), _cx), className),
    activeClasses = cx(isTopNavBar ? styles.activeTopNavBarItem : styles.activeLeftNavBarItem, activeClassName),
    iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      className: styles.navBarItemIcon,
      size: "medium"
    }),
    title = React.createElement("span", {
      className: styles.title
    }, translator(header)),
    content = isTopNavBar ? title : React.createElement("div", {
      className: styles.titleContentWrapper
    }, title, renderBadge(notifications, isInternalLink && getIdFromPath(to))),
    clickHandler = useCallback(function (event) {
      var _event$persist;
      null === (_event$persist = event.persist) || void 0 === _event$persist || _event$persist.call(event), setActiveRoute(to);
    }, [setActiveRoute, to]),
    commonLinkProps = {
      disabled: disabled,
      className: classes,
      type: "link",
      role: "menuitem"
    };
  return isInternalLink ? React.createElement(NavLink, _extends({}, commonLinkProps, {
    to: to,
    exact: exact,
    activeClassName: activeClasses,
    tabIndex: disabled ? -1 : 0,
    onClick: clickHandler,
    innerRef: linkRef
  }), React.createElement("div", {
    className: styles.titleWrapper
  }, iconComponent, content)) : React.createElement(Link, _extends({}, commonLinkProps, {
    ref: linkRef,
    textClassName: styles.titleWrapper,
    inline: !1,
    href: href,
    target: "_blank"
  }), iconComponent, content);
};
NavBarLinkInternal.propTypes = {
  className: PropTypes.string,
  header: intlMessageShape.isRequired,
  to: intlMessageShape,
  href: intlMessageShape,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  activeClassName: PropTypes.string,
  alignment: PropTypes.oneOf(["top", "left"]),
  notifications: PropTypes.number
}, NavBarLinkInternal.displayName = "NavBarLink";
export var NavBarLink = NavBarLinkInternal;
NavBarLinkInternal.__docgenInfo = {
  description: "The NavBarLink component is designed as a simple component for presenting a navigation link inside the NavBar container.\n\n@param {Array<any>} props properties for NavBarLink component\n\n@returns {React.ReactElement} JSX for the NavBarLink component",
  methods: [],
  displayName: "NavBarLink",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "custom",
        raw: "intlMessageShape.isRequired"
      },
      required: !1,
      description: "Link header"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "Internal path"
    },
    href: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "External path"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to make the item disabled"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the active component"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Navigation alignment"
    },
    notifications: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The value of the Badge"
    }
  }
}, NavBarLinkInternal.__docgenInfo = {
  componentName: "NavBarLink",
  packageName: "@jutro/router",
  description: "The NavBarLink component is designed as a simple component for presenting a navigation link inside the NavBar container.",
  displayName: "NavBarLink",
  methods: [],
  actualName: "NavBarLinkInternal",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the component"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !0,
      description: "Link header"
    },
    to: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Internal path"
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "External path"
    },
    icon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Icon to render to the left of the header"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Flag to make the item disabled"
    },
    activeClassName: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional class names for the active component"
    },
    alignment: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: !1
        }, {
          value: "'left'",
          computed: !1
        }]
      },
      required: !1,
      description: "Navigation alignment"
    },
    notifications: {
      type: {
        name: "number"
      },
      required: !1,
      description: "The value of the Badge"
    }
  }
};