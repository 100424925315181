import "core-js/modules/es.regexp.exec";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context14;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context14 = Object.prototype.toString.call(o)).call(_context14, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Set from "@babel/runtime-corejs3/core-js-stable/set";
import _flatMapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat-map";
import union from "lodash/union";
import intersection from "lodash/intersection";
import property from "lodash/property";
import { findParent, next, prev, getEnabledNodes, getVisibleNodes } from "./traverse";
export var ACTIONS;
!function (ACTIONS) {
  ACTIONS[ACTIONS.TOGGLE = 0] = "TOGGLE", ACTIONS[ACTIONS.TOGGLE_ALL = 1] = "TOGGLE_ALL", ACTIONS[ACTIONS.REGISTER = 2] = "REGISTER", ACTIONS[ACTIONS.UNREGISTER = 3] = "UNREGISTER", ACTIONS[ACTIONS.FILTER_CHANGE = 4] = "FILTER_CHANGE", ACTIONS[ACTIONS.KEY_DOWN = 5] = "KEY_DOWN", ACTIONS[ACTIONS.CHANGE_ACTIVE_NODE = 6] = "CHANGE_ACTIVE_NODE", ACTIONS[ACTIONS.CHANGE_SELECTED_NODE = 7] = "CHANGE_SELECTED_NODE";
}(ACTIONS || (ACTIONS = {}));
var isNodeExpanded = function (expandedNodes, id) {
    return _includesInstanceProperty(expandedNodes).call(expandedNodes, id);
  },
  getExpandedNodes = function (expandedNodes, idToToggle) {
    var _context;
    return {
      expandedNodes: isNodeExpanded(expandedNodes, idToToggle) ? _filterInstanceProperty(expandedNodes).call(expandedNodes, function (id) {
        return id !== idToToggle;
      }) : _concatInstanceProperty(_context = []).call(_context, _toConsumableArray(expandedNodes), [idToToggle])
    };
  };
export var treeReducer = function (state, action) {
  switch (action.type) {
    case ACTIONS.REGISTER:
      var _context6,
        _context7,
        newNode = action.payload.newNode,
        missingAncestors = (node = newNode, nodeList = state.nodeMap, _reduceInstanceProperty(_context4 = node.path).call(_context4, function (accumulator, ancestorId) {
          var _context5;
          return _findInstanceProperty(nodeList).call(nodeList, function (_ref3) {
            return _ref3.id === ancestorId;
          }) ? accumulator : _concatInstanceProperty(_context5 = []).call(_context5, _toConsumableArray(accumulator), [{
            id: ancestorId,
            disabled: !1,
            label: "",
            path: []
          }]);
        }, [])),
        newNodeMap = _concatInstanceProperty(_context6 = []).call(_context6, _toConsumableArray(state.nodeMap), _toConsumableArray(missingAncestors)),
        alreadyExistingNodeIndex = _findIndexInstanceProperty(_context7 = state.nodeMap).call(_context7, function (node) {
          return node.id === newNode.id;
        });
      return alreadyExistingNodeIndex >= 0 ? newNodeMap[alreadyExistingNodeIndex] = newNode : newNodeMap.push(newNode), _objectSpread(_objectSpread({}, state), {}, {
        nodeMap: newNodeMap
      });
    case ACTIONS.UNREGISTER:
      var _context8,
        unregisterId = action.payload.id;
      return _objectSpread(_objectSpread({}, state), {}, {
        nodeMap: _filterInstanceProperty(_context8 = state.nodeMap).call(_context8, function (node) {
          return node.id !== unregisterId;
        })
      });
    case ACTIONS.TOGGLE:
      var id = action.payload.id;
      return _objectSpread(_objectSpread({}, state), getExpandedNodes(state.expandedNodes, id));
    case ACTIONS.TOGGLE_ALL:
      var _context9,
        _context10,
        _context11,
        _context12,
        _context13,
        allCollapsed = 0 === state.expandedNodes.length,
        enabledNodes = _mapInstanceProperty(_context9 = getEnabledNodes(state.nodeMap)).call(_context9, property("id")),
        ancestorsToExpandIds = new _Set(_flatMapInstanceProperty(_context10 = _filterInstanceProperty(_context11 = state.nodeMap).call(_context11, function (_ref4) {
          var path = _ref4.path;
          return intersection(path, enabledNodes).length === path.length;
        })).call(_context10, function (_ref5) {
          return _ref5.path;
        }));
      return _objectSpread(_objectSpread({}, state), {}, {
        expandedNodes: allCollapsed ? _mapInstanceProperty(_context12 = _filterInstanceProperty(_context13 = state.nodeMap).call(_context13, function (_ref6) {
          var id = _ref6.id,
            disabled = _ref6.disabled;
          return ancestorsToExpandIds.has(id) && !disabled;
        })).call(_context12, property("id")) : []
      });
    case ACTIONS.FILTER_CHANGE:
      var _action$payload = action.payload,
        query = _action$payload.query,
        filterCallback = _action$payload.filterCallback,
        filterQuery = state.filterQuery,
        expandedNodes = state.expandedNodes,
        expandedNodesSnapshot = state.expandedNodesSnapshot,
        stateUpdate = {
          matchedNodes: []
        };
      return !filterQuery && query && (stateUpdate.expandedNodesSnapshot = expandedNodes), stateUpdate.filterQuery = query, stateUpdate.activeNodeId = "", query ? _objectSpread(_objectSpread(_objectSpread({}, state), stateUpdate), function (nodeMap, query, filterCallback) {
        var _step,
          expandedNodes = [],
          matchedNodes = [],
          _iterator = _createForOfIteratorHelper(nodeMap);
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _ref2 = _step.value,
              id = _ref2.id,
              label = _ref2.label,
              path = _ref2.path;
            if (label) {
              var _context3,
                matches = filterCallback(label, query);
              matches && (matchedNodes = _concatInstanceProperty(_context3 = []).call(_context3, _toConsumableArray(matchedNodes), [{
                id: id,
                label: label,
                path: path,
                matches: matches
              }]), expandedNodes = union(expandedNodes, path));
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        return {
          expandedNodes: expandedNodes,
          matchedNodes: matchedNodes
        };
      }(state.nodeMap, query, filterCallback)) : (stateUpdate.expandedNodes = expandedNodesSnapshot, _objectSpread(_objectSpread({}, state), stateUpdate));
    case ACTIONS.KEY_DOWN:
      return function (state, key) {
        var getNext = function () {
          return next({
            nodeMap: state.nodeMap,
            expandedNodes: state.expandedNodes,
            activeNodeId: state.activeNodeId,
            matchedNodes: state.matchedNodes,
            filterQuery: state.filterQuery
          });
        };
        switch (key) {
          case "ArrowDown":
            return _objectSpread(_objectSpread({}, state), {}, {
              activeNodeId: getNext().id
            });
          case "ArrowUp":
            return _objectSpread(_objectSpread({}, state), {}, {
              activeNodeId: prev({
                nodeMap: state.nodeMap,
                expandedNodes: state.expandedNodes,
                activeNodeId: state.activeNodeId,
                matchedNodes: state.matchedNodes,
                filterQuery: state.filterQuery
              }).id
            });
          case "ArrowRight":
            return isNodeExpanded(state.expandedNodes, state.activeNodeId) ? _objectSpread(_objectSpread({}, state), {}, {
              activeNodeId: getNext().id
            }) : _objectSpread(_objectSpread({}, state), getExpandedNodes(state.expandedNodes, state.activeNodeId));
          case "ArrowLeft":
            if (isNodeExpanded(state.expandedNodes, state.activeNodeId)) return _objectSpread(_objectSpread({}, state), getExpandedNodes(state.expandedNodes, state.activeNodeId));
            var parent = findParent(state.nodeMap, state.activeNodeId);
            return parent ? _objectSpread(_objectSpread({}, state), {}, {
              activeNodeId: parent.id
            }) : state;
          case "End":
            var _context2,
              nodeMap = state.nodeMap,
              expandedNodes = state.expandedNodes,
              matchedNodes = state.matchedNodes,
              filterQuery = state.filterQuery,
              _getEnabledNodes$slic = _sliceInstanceProperty(_context2 = getEnabledNodes(getVisibleNodes({
                nodeMap: nodeMap,
                expandedNodes: expandedNodes,
                matchedNodes: matchedNodes,
                filterQuery: filterQuery
              }))).call(_context2, -1),
              lastNode = _slicedToArray(_getEnabledNodes$slic, 1)[0];
            return _objectSpread(_objectSpread({}, state), {}, {
              activeNodeId: lastNode.id
            });
          case "Home":
            var _nodeMap = state.nodeMap,
              _expandedNodes = state.expandedNodes,
              _matchedNodes = state.matchedNodes,
              _filterQuery = state.filterQuery,
              _getEnabledNodes = getEnabledNodes(getVisibleNodes({
                nodeMap: _nodeMap,
                expandedNodes: _expandedNodes,
                matchedNodes: _matchedNodes,
                filterQuery: _filterQuery
              })),
              firstNode = _slicedToArray(_getEnabledNodes, 1)[0];
            return _objectSpread(_objectSpread({}, state), {}, {
              activeNodeId: firstNode.id
            });
          case "Enter":
            return _objectSpread(_objectSpread({}, state), {}, {
              selectedNodeId: state.activeNodeId
            });
          default:
            return state;
        }
      }(state, action.payload.key);
    case ACTIONS.CHANGE_ACTIVE_NODE:
      var _id = action.payload.id;
      return _objectSpread(_objectSpread({}, state), {}, {
        activeNodeId: _id
      });
    case ACTIONS.CHANGE_SELECTED_NODE:
      var _id2 = action.payload.id;
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedNodeId: _id2
      });
    default:
      return state;
  }
  var node, nodeList, _context4;
};