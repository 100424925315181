import "core-js/modules/es.regexp.exec";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _endsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/ends-with";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
export var isFileType = function (file, mimeTypeOrExtension) {
  var _context;
  return _startsWithInstanceProperty(mimeTypeOrExtension).call(mimeTypeOrExtension, ".") ? _endsWithInstanceProperty(_context = file.name.toLowerCase()).call(_context, mimeTypeOrExtension.toLowerCase()) : function (fileMimeType, targetMimeType) {
    var _fileMimeType$toLower = fileMimeType.toLowerCase().split("/"),
      _fileMimeType$toLower2 = _slicedToArray(_fileMimeType$toLower, 2),
      fileMimePrefix = _fileMimeType$toLower2[0],
      fileMimeSuffix = _fileMimeType$toLower2[1],
      _targetMimeType$toLow = targetMimeType.toLowerCase().split("/"),
      _targetMimeType$toLow2 = _slicedToArray(_targetMimeType$toLow, 2),
      targetMimePrefix = _targetMimeType$toLow2[0],
      targetMimeSuffix = _targetMimeType$toLow2[1];
    if (fileMimePrefix !== targetMimePrefix) return !1;
    if ("*" === targetMimeSuffix) return !0;
    return fileMimeSuffix === targetMimeSuffix;
  }(file.type, mimeTypeOrExtension);
};
export var isFileOfAllowedType = function (file, accept) {
  var _context2;
  return _mapInstanceProperty(_context2 = accept.toLowerCase().split(",")).call(_context2, function (item) {
    return _trimInstanceProperty(item).call(item);
  }).some(function (type) {
    return isFileType(file, type);
  });
};