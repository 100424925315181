import "core-js/modules/es.regexp.exec";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
import _toConsumableArray from "@babel/runtime-corejs3/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context8;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context8 = Object.prototype.toString.call(o)).call(_context8, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
var getMessageShape = function (message) {
    return "string" == typeof message ? {
      message: message,
      type: "error"
    } : "object" === _typeof(message) && "message" in message ? _objectSpread(_objectSpread({}, message), {}, {
      message: message.message,
      type: message.type || "error"
    }) : void 0;
  },
  getResult = function (_ref) {
    var rule = _ref.rule,
      data = _ref.data,
      path = _ref.path,
      translator = _ref.translator;
    if (isFunction(rule)) {
      if (!data) {
        var _result = rule(data, path, translator);
        return getMessageShape(_result);
      }
      var result = rule(data, path, translator);
      return getMessageShape(result);
    }
    return getMessageShape(rule);
  },
  getPaths = function (rules) {
    return rules ? _Object$keys(rules) : [];
  },
  makeArray = function (x) {
    return Array.isArray(x) ? x : [x];
  },
  getGeneralFormValidationCallbacks = function (_ref7) {
    var formData = _ref7.formData,
      formRules = _ref7.formRules,
      translator = _ref7.translator;
    return null != formRules && formRules["*"] ? function (_ref4) {
      var _step2,
        rules = _ref4.rules,
        path = _ref4.path,
        translator = _ref4.translator,
        data = _ref4.data,
        _iterator2 = _createForOfIteratorHelper(Array.isArray(rules) ? rules : [rules]);
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var rule = _step2.value;
          if (isFunction(rule)) {
            var _context2,
              result = rule(data, path, translator);
            if (result instanceof Object && !isEmpty(result)) return _reduceInstanceProperty(_context2 = _Object$entries(result)).call(_context2, function (acc, _ref5) {
              var _ref6 = _slicedToArray(_ref5, 2),
                resultPath = _ref6[0],
                resultMessage = _ref6[1];
              return "object" === _typeof(resultMessage) && "message" in resultMessage ? _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, resultPath, [_objectSpread(_objectSpread({}, resultMessage), {}, {
                message: translator(resultMessage.message)
              })])) : _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, resultPath, [{
                message: resultMessage,
                type: "error"
              }]));
            }, {});
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      return {};
    }({
      rules: formRules["*"],
      path: "*",
      data: formData,
      translator: translator
    }) : {};
  },
  getPathCallbackMap = function (_ref11) {
    var _context5,
      fieldRules = _ref11.fieldRules,
      formRules = _ref11.formRules,
      formData = _ref11.formData,
      nestedFormMessages = _ref11.nestedFormMessages,
      translator = _ref11.translator,
      nestedFormValidationMap = function (nestedFormMessages) {
        var _context4;
        return isEmpty(nestedFormMessages) || void 0 === nestedFormMessages ? {} : _reduceInstanceProperty(_context4 = _Object$entries(nestedFormMessages)).call(_context4, function (acc, _ref9) {
          var _ref10 = _slicedToArray(_ref9, 2),
            path = _ref10[0],
            pathMessage = _ref10[1];
          return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, path, [pathMessage]));
        }, {});
      }(nestedFormMessages),
      generalFormValidationMap = getGeneralFormValidationCallbacks({
        formData: formData,
        formRules: formRules,
        translator: translator
      }),
      fieldValidationMap = function (_ref8) {
        var fieldRules = _ref8.fieldRules;
        if (!fieldRules) return {};
        var paths = getPaths(fieldRules);
        return _reduceInstanceProperty(paths).call(paths, function (acc, path) {
          return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, path, makeArray(fieldRules[path])));
        }, {});
      }({
        fieldRules: fieldRules
      }),
      formValidationMap = function (formRules) {
        var _context3;
        if (!formRules) return {};
        var formPaths = getPaths(formRules);
        return _reduceInstanceProperty(_context3 = _filterInstanceProperty(formPaths).call(formPaths, function (path) {
          return "*" !== path;
        })).call(_context3, function (acc, path) {
          return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, path, makeArray(formRules[path])));
        }, {});
      }(formRules),
      formKeys = uniq(_concatInstanceProperty(_context5 = []).call(_context5, _toConsumableArray(_Object$keys(fieldValidationMap)), _toConsumableArray(_Object$keys(generalFormValidationMap)), _toConsumableArray(_Object$keys(formValidationMap)), _toConsumableArray(_Object$keys(nestedFormValidationMap))));
    return _reduceInstanceProperty(formKeys).call(formKeys, function (acc, path) {
      var _context6;
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, path, _concatInstanceProperty(_context6 = []).call(_context6, _toConsumableArray(get(generalFormValidationMap, path, [])), _toConsumableArray(get(fieldValidationMap, path, [])), _toConsumableArray(get(formValidationMap, path, [])), _toConsumableArray(get(nestedFormValidationMap, path, [])))));
    }, {});
  };
export var triggerValidation = function (_ref12) {
  var _context7,
    fieldRules = _ref12.fieldRules,
    formRules = _ref12.formRules,
    formData = _ref12.formData,
    nestedFormMessages = _ref12.nestedFormMessages,
    translator = _ref12.translator,
    _ref12$multipleMessag = _ref12.multipleMessagesMap,
    multipleMessagesMap = void 0 === _ref12$multipleMessag ? {} : _ref12$multipleMessag,
    pathMap = getPathCallbackMap({
      fieldRules: fieldRules,
      formRules: formRules,
      formData: formData,
      nestedFormMessages: nestedFormMessages,
      translator: translator
    }),
    messages = _reduceInstanceProperty(_context7 = _Object$entries(pathMap)).call(_context7, function (acc, _ref13) {
      var _ref14 = _slicedToArray(_ref13, 2),
        path = _ref14[0],
        pathRules = _ref14[1];
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, path, multipleMessagesMap[path] ? function (_ref2) {
        var _context,
          data = _ref2.data,
          path = _ref2.path,
          pathRules = _ref2.pathRules,
          translator = _ref2.translator,
          effectiveRules = Array.isArray(pathRules) ? pathRules : [pathRules],
          messages = _filterInstanceProperty(_context = _mapInstanceProperty(effectiveRules).call(effectiveRules, function (rule) {
            return getResult({
              rule: rule,
              data: data,
              path: path,
              translator: translator
            });
          })).call(_context, function (x) {
            return !!x;
          });
        return isEmpty(messages) ? void 0 : messages;
      }({
        data: get(formData, path),
        path: path,
        pathRules: pathRules,
        translator: translator
      }) : function (_ref3) {
        var _step,
          data = _ref3.data,
          path = _ref3.path,
          pathRules = _ref3.pathRules,
          translator = _ref3.translator,
          _iterator = _createForOfIteratorHelper(Array.isArray(pathRules) ? pathRules : [pathRules]);
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var rule = _step.value,
              result = getResult({
                rule: rule,
                data: data,
                path: path,
                translator: translator
              });
            if (result) return result;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }({
        data: get(formData, path),
        path: path,
        pathRules: pathRules,
        translator: translator
      })));
    }, {});
  return omitBy(messages, isUndefined);
};