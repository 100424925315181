import "core-js/modules/es.regexp.exec";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _dec, _class;
import _URLSearchParams from "@babel/runtime-corejs3/core-js-stable/url-search-params";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { ServiceManager, service } from "@jutro/services";
import { log } from "@jutro/logger";
import { getConfigValue } from "@jutro/config";
import { logDeprecationMessage } from "@jutro/platform";
import localeCodes from "countries-list";
import customLanguagesList from "./customLanguages.json";
import LocaleSettingsInterface from "./LocaleSettingsInterface";
import { getBrowserLanguage, getCountryCodeFromLocale, getLanguageFromLocale } from "./locale";
export var LOCALE_SERVICE_ID = "locale";
export var LOCALE_URL_PARAM = "selectedLocale";
export var LANGUAGE_URL_PARAM = "selectedLanguage";
var localeSettings,
  localeConfiguration,
  countriesList = localeCodes.countries,
  languagesList = localeCodes.languagesAll,
  languagesListWithPseudoLocales = _objectSpread(_objectSpread({}, customLanguagesList), languagesList),
  getLocaleSettings = function () {
    return localeSettings || (localeSettings = ServiceManager.getServiceByContract(LocaleSettingsInterface), logDeprecationMessage("Fetching locale settings from service", "Please use config.json file to store locale settings")), localeSettings;
  },
  getLocaleConfig = function () {
    return localeConfiguration || (localeConfiguration = getConfigValue("localeSettings")), localeConfiguration;
  },
  getUrlParam = function (paramName) {
    return new _URLSearchParams(window.location.search).get(paramName);
  },
  _getStoredLocale = function () {
    var skipLocalStoragePersistence = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      skipURLPersistence = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (!skipURLPersistence) {
      var urlValue = getUrlParam(LOCALE_URL_PARAM);
      if (urlValue) return urlValue;
    }
    if (!skipLocalStoragePersistence) {
      var localStorageValue = localStorage.getItem("selectedLocale");
      if (localStorageValue) return localStorageValue;
    }
    return null;
  },
  _getStoredLanguage = function () {
    var skipLocalStoragePersistence = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      skipURLPersistence = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (!skipURLPersistence) {
      var urlValue = getUrlParam(LANGUAGE_URL_PARAM);
      if (urlValue) return urlValue;
    }
    if (!skipLocalStoragePersistence) {
      var localStorageValue = localStorage.getItem("selectedLanguage");
      if (localStorageValue) return localStorageValue;
    }
    return null;
  },
  saveLocale = function (locale) {
    localStorage.setItem("selectedLocale", locale);
  },
  saveLanguage = function (language) {
    localStorage.setItem("selectedLanguage", language);
  };
export var getNativeLocaleLanguage = function (locale) {
  var _context,
    languageCode = getLanguageFromLocale(locale),
    countryCode = getCountryCodeFromLocale(locale),
    languageMapping = void 0 !== languageCode ? languagesList[languageCode] : void 0,
    countryMapping = void 0 !== countryCode ? countriesList[countryCode] : void 0;
  return languageMapping && countryMapping ? _concatInstanceProperty(_context = "".concat(languageMapping.native, " (")).call(_context, countryMapping.native, ")") : void 0 !== languageCode && languagesListWithPseudoLocales[locale] ? languagesListWithPseudoLocales[locale].native : void 0;
};
var findAvailableLocale = function () {
    var browserLanguage = getBrowserLanguage(),
      availableLocales = _getAvailableLocales();
    if (availableLocales && availableLocales.length && _includesInstanceProperty(availableLocales).call(availableLocales, browserLanguage)) return browserLanguage;
    var preferredLocale = getPreferredLocale();
    return preferredLocale || browserLanguage;
  },
  findAvailableLanguage = function () {
    var browserLanguage = getBrowserLanguage(),
      availableLanguages = _getAvailableLanguages();
    if (availableLanguages && availableLanguages.length && _includesInstanceProperty(availableLanguages).call(availableLanguages, browserLanguage)) return browserLanguage;
    var preferredLanguage = getPreferredLanguage();
    return preferredLanguage || browserLanguage;
  },
  _getAvailableLanguages = function () {
    var settings = getLocaleSettings(),
      localeConfig = getLocaleConfig();
    return settings ? settings.getAvailableLanguages() : localeConfig && localeConfig.availableLanguages ? localeConfig.availableLanguages : [];
  },
  _getAvailableLocales = function () {
    var settings = getLocaleSettings(),
      localeConfig = getLocaleConfig();
    return settings ? settings.getAvailableLocales() : localeConfig && localeConfig.availableLocales ? localeConfig.availableLocales : (log.warning("No locale configuration or settings provided."), []);
  },
  getPreferredLanguage = function () {
    var settings = getLocaleSettings(),
      localeConfig = getLocaleConfig();
    return settings ? settings.getPreferredLanguage() : localeConfig && localeConfig.preferredLanguage ? localeConfig.preferredLanguage : (log.warning("No locale configuration or settings provided."), null);
  },
  getPreferredLocale = function () {
    var settings = getLocaleSettings(),
      localeConfig = getLocaleConfig();
    return settings ? settings.getPreferredLocale() : localeConfig && localeConfig.preferredLocale ? localeConfig.preferredLocale : (log.warning("No locale configuration or settings provided."), null);
  };
export var getDefaultCountryCode = function () {
  var settings = getLocaleSettings(),
    localeConfig = getLocaleConfig();
  return settings ? settings.getDefaultCountryCode() : localeConfig && localeConfig.defaultCountryCode ? localeConfig.defaultCountryCode : (log.warning("No locale configuration or settings provided."), null);
};
export var getDefaultCurrency = function () {
  var localeConfig = getLocaleConfig();
  return localeConfig && localeConfig.defaultCurrency ? localeConfig.defaultCurrency : (log.warning("No locale configuration provided."), "USD");
};
export var getDefaultTimeZone = function () {
  var localeConfig = getLocaleConfig(),
    fallbackTimeZoneCode = getTimeZoneFallback();
  return localeConfig && localeConfig.defaultTimeZone ? localeConfig.defaultTimeZone : fallbackTimeZoneCode;
};
export var getTimeZoneFallback = function () {
  var _Intl$DateTimeFormat$, _Intl$DateTimeFormat;
  return (null === (_Intl$DateTimeFormat$ = (_Intl$DateTimeFormat = Intl.DateTimeFormat()).resolvedOptions) || void 0 === _Intl$DateTimeFormat$ ? void 0 : _Intl$DateTimeFormat$.call(_Intl$DateTimeFormat).timeZone) || "UTC";
};
export var LocaleService = (_dec = service(LOCALE_SERVICE_ID), _dec(_class = function () {
  function LocaleService() {
    _classCallCheck(this, LocaleService), _defineProperty(this, "localeChangeListeners", void 0), _defineProperty(this, "languageChangeListeners", void 0), _defineProperty(this, "currentLocale", void 0), _defineProperty(this, "currentLanguage", void 0), _defineProperty(this, "storedLanguageWasRead", !1), _defineProperty(this, "storedLocaleWasRead", !1), this.localeChangeListeners = [], this.languageChangeListeners = [];
  }
  return _createClass(LocaleService, [{
    key: "settings",
    get: function () {
      return getLocaleSettings();
    }
  }, {
    key: "locale",
    get: function () {
      return this.getCurrentLocale();
    },
    set: function (newLocale) {
      this.setCurrentLocale(newLocale);
    }
  }, {
    key: "language",
    get: function () {
      return this.getCurrentLanguage();
    },
    set: function (newLanguage) {
      this.setCurrentLanguage(newLanguage);
    }
  }, {
    key: "getAvailableLocales",
    value: function () {
      return _getAvailableLocales();
    }
  }, {
    key: "getAvailableLanguages",
    value: function () {
      return _getAvailableLanguages();
    }
  }, {
    key: "getCountry",
    value: function () {
      return this.getCurrentCountry() || getDefaultCountryCode();
    }
  }, {
    key: "getCurrentCountry",
    value: function () {
      return getCountryCodeFromLocale(this.locale);
    }
  }, {
    key: "getCurrentLocale",
    value: function () {
      var skipLocalStoragePersistence = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
        skipURLPersistence = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
      return this.initFromStoredLocale(skipLocalStoragePersistence, skipURLPersistence), this.currentLocale || this.setCurrentLocale(findAvailableLocale(), skipLocalStoragePersistence), this.currentLocale;
    }
  }, {
    key: "getCurrentLanguage",
    value: function () {
      var skipLocalStoragePersistence = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
        skipURLPersistence = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
      return this.initFromStoredLanguage(skipLocalStoragePersistence, skipURLPersistence), this.currentLanguage || this.setCurrentLanguage(findAvailableLanguage(), skipLocalStoragePersistence), this.currentLanguage;
    }
  }, {
    key: "initFromStoredLocale",
    value: function (skipLocalStoragePersistence, skipURLPersistence) {
      if (!this.storedLocaleWasRead) {
        var storedLocale = _getStoredLocale(skipLocalStoragePersistence, skipURLPersistence);
        storedLocale && (this.currentLocale = storedLocale), this.storedLocaleWasRead = !0;
      }
    }
  }, {
    key: "initFromStoredLanguage",
    value: function (skipLocalStoragePersistence, skipURLPersistence) {
      if (!this.storedLanguageWasRead) {
        var storedLanguage = _getStoredLanguage(skipLocalStoragePersistence, skipURLPersistence);
        storedLanguage && (this.currentLanguage = storedLanguage), this.storedLanguageWasRead = !0;
      }
    }
  }, {
    key: "getStoredLocale",
    value: function () {
      return _getStoredLocale();
    }
  }, {
    key: "getStoredLanguage",
    value: function () {
      return _getStoredLanguage();
    }
  }, {
    key: "setCurrentLocale",
    value: function (newLocale) {
      var skipLocalStoragePersistence = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
      this.currentLocale = newLocale, skipLocalStoragePersistence || saveLocale(newLocale), this.localeChangeListeners.forEach(function (listener) {
        return listener(newLocale);
      });
    }
  }, {
    key: "setCurrentLanguage",
    value: function (newLanguage) {
      var skipLocalStoragePersistence = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
      this.currentLanguage = newLanguage, skipLocalStoragePersistence || saveLanguage(newLanguage), this.languageChangeListeners.forEach(function (listener) {
        return listener(newLanguage);
      });
    }
  }, {
    key: "onLocaleChange",
    value: function (callback) {
      this.localeChangeListeners.push(callback);
    }
  }, {
    key: "onLanguageChange",
    value: function (callback) {
      this.languageChangeListeners.push(callback);
    }
  }, {
    key: "getConfiguredPhoneRegion",
    value: function () {
      return getDefaultCountryCode();
    }
  }]), LocaleService;
}()) || _class);
export default ServiceManager.getService(LOCALE_SERVICE_ID);