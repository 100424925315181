import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _excluded = ["defaultParentIcon", "defaultEndIcon", "defaultRenderIcon", "defaultRenderExpandIcon", "defaultRenderChildren", "defaultRenderHeader", "nodes", "showFilter", "renderFilter", "filterProps", "filterCallback", "hideCollapseAll", "renderCollapseAll", "onClick", "children", "className", "renderNodes", "renderNoResults"];
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _matchAllInstanceProperty from "@babel/runtime-corejs3/core-js/instance/match-all";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import cx from "classnames";
import { useBreakpoint } from "@jutro/layout";
import { intlMessageShape } from "@jutro/prop-types";
import { warning } from "@jutro/logger";
import { TreeViewContext } from "./TreeViewContext";
import { defaultRenderNodes } from "./defaultRenderNodes";
import { TreeViewFilter, treeViewFilterProps } from "./TreeViewFilter";
import styles from "./TreeView.module.css";
import { useTree } from "./useTree";
import { TreeViewCollapseAll } from "./TreeViewCollapseAll";
import { TreeViewNoResults } from "./TreeViewNoResults";
var defaultRenderFilter = function (props) {
    return React.createElement(TreeViewFilter, props);
  },
  defaultFilterCallback = function (value, query) {
    var _context,
      matches = _mapInstanceProperty(_context = _Array$from(_matchAllInstanceProperty(value).call(value, new RegExp(query, "gi")))).call(_context, function (_ref) {
        var _ref$index = _ref.index,
          index = void 0 === _ref$index ? 0 : _ref$index;
        return {
          start: index,
          end: index + query.length
        };
      });
    return matches.length > 0 ? matches : void 0;
  };
export var TreeView = function (props) {
  var _useBreakpoint$breakp = useBreakpoint(props).breakpointProps,
    defaultParentIcon = _useBreakpoint$breakp.defaultParentIcon,
    defaultEndIcon = _useBreakpoint$breakp.defaultEndIcon,
    defaultRenderIcon = _useBreakpoint$breakp.defaultRenderIcon,
    defaultRenderExpandIcon = _useBreakpoint$breakp.defaultRenderExpandIcon,
    defaultRenderChildren = _useBreakpoint$breakp.defaultRenderChildren,
    defaultRenderHeader = _useBreakpoint$breakp.defaultRenderHeader,
    nodes = _useBreakpoint$breakp.nodes,
    showFilter = _useBreakpoint$breakp.showFilter,
    renderFilter = _useBreakpoint$breakp.renderFilter,
    filterProps = _useBreakpoint$breakp.filterProps,
    _useBreakpoint$breakp2 = _useBreakpoint$breakp.filterCallback,
    filterCallback = void 0 === _useBreakpoint$breakp2 ? defaultFilterCallback : _useBreakpoint$breakp2,
    hideCollapseAll = _useBreakpoint$breakp.hideCollapseAll,
    _useBreakpoint$breakp3 = _useBreakpoint$breakp.renderCollapseAll,
    RenderCollapseAll = void 0 === _useBreakpoint$breakp3 ? TreeViewCollapseAll : _useBreakpoint$breakp3,
    onClick = _useBreakpoint$breakp.onClick,
    children = _useBreakpoint$breakp.children,
    className = _useBreakpoint$breakp.className,
    _useBreakpoint$breakp4 = _useBreakpoint$breakp.renderNodes,
    RenderNodes = void 0 === _useBreakpoint$breakp4 ? defaultRenderNodes : _useBreakpoint$breakp4,
    _useBreakpoint$breakp5 = _useBreakpoint$breakp.renderNoResults,
    RenderNoResults = void 0 === _useBreakpoint$breakp5 ? TreeViewNoResults : _useBreakpoint$breakp5,
    other = _objectWithoutProperties(_useBreakpoint$breakp, _excluded),
    _useTree = useTree({
      getInitialState: function () {
        return {
          nodeMap: [],
          activeNodeId: "",
          selectedNodeId: "",
          expandedNodes: [],
          expandedNodesSnapshot: [],
          matchedNodes: [],
          filterQuery: void 0
        };
      },
      filterCallback: filterCallback
    }),
    _useTree$state = _useTree.state,
    activeNodeId = _useTree$state.activeNodeId,
    selectedNodeId = _useTree$state.selectedNodeId,
    filterQuery = _useTree$state.filterQuery,
    matchedNodes = _useTree$state.matchedNodes,
    toggleNode = _useTree.toggleNode,
    toggleAllNodes = _useTree.toggleAllNodes,
    registerNode = _useTree.registerNode,
    unregisterNode = _useTree.unregisterNode,
    onFilterChange = _useTree.onFilterChange,
    isNodeExpanded = _useTree.isNodeExpanded,
    handleOnKeyDown = _useTree.handleOnKeyDown,
    setActiveNodeId = _useTree.setActiveNodeId,
    setSelectedNodeId = _useTree.setSelectedNodeId,
    isNodeVisible = _useTree.isNodeVisible,
    isFirstNode = _useTree.isFirstNode,
    getMatchedLabelParts = _useTree.getMatchedLabelParts,
    allCollapsed = _useTree.allCollapsed,
    filterComponent = renderFilter ? renderFilter(debounce(onFilterChange, 500)) : defaultRenderFilter(_objectSpread(_objectSpread({}, filterProps), {}, {
      onChange: debounce(onFilterChange, 500)
    })),
    collapseAllCommonProps = {
      allCollapsed: allCollapsed,
      onClick: toggleAllNodes,
      disabled: !!filterQuery
    },
    collapseAllComponent = React.createElement(RenderCollapseAll, collapseAllCommonProps);
  children && nodes && warning("TreeView: children and nodes are provided simultaneously and nodes configuration is ignored.");
  var noResultsComponent = React.createElement(RenderNoResults, null),
    classes = cx(styles.treeView, className),
    hasNoFitlerResults = filterQuery && 0 === matchedNodes.length,
    showTree = !hasNoFitlerResults,
    treeViewContext = useMemo(function () {
      return {
        registerNode: registerNode,
        unregisterNode: unregisterNode,
        toggleNode: toggleNode,
        isNodeExpanded: isNodeExpanded,
        isNodeVisible: isNodeVisible,
        isFirstNode: isFirstNode,
        onClick: onClick,
        getMatchedLabelParts: getMatchedLabelParts,
        defaultParentIcon: defaultParentIcon,
        defaultEndIcon: defaultEndIcon,
        defaultRenderIcon: defaultRenderIcon,
        defaultRenderExpandIcon: defaultRenderExpandIcon,
        defaultRenderChildren: defaultRenderChildren,
        defaultRenderHeader: defaultRenderHeader,
        activeNodeId: activeNodeId,
        setActiveNodeId: setActiveNodeId,
        selectedNodeId: selectedNodeId,
        setSelectedNodeId: setSelectedNodeId
      };
    }, [registerNode, unregisterNode, toggleNode, isNodeExpanded, isNodeVisible, isFirstNode, onClick, getMatchedLabelParts, defaultParentIcon, defaultEndIcon, defaultRenderIcon, defaultRenderExpandIcon, defaultRenderChildren, defaultRenderHeader, activeNodeId, setActiveNodeId, selectedNodeId, setSelectedNodeId]);
  return React.createElement(TreeViewContext.Provider, {
    value: treeViewContext
  }, React.createElement("div", null, showFilter && filterComponent, !hideCollapseAll && showTree && collapseAllComponent, showTree && React.createElement("ul", _extends({
    role: "tree",
    className: classes
  }, other, {
    onKeyDown: handleOnKeyDown
  }), children || nodes && React.createElement(RenderNodes, {
    nodes: nodes,
    nodeId: "root"
  })), hasNoFitlerResults && noResultsComponent));
};
var treeNodePropTypes = {
    id: PropTypes.string.isRequired,
    label: intlMessageShape.isRequired
  },
  treeNodeShape = PropTypes.shape(treeNodePropTypes);
treeNodePropTypes.nodes = PropTypes.arrayOf(treeNodeShape.isRequired), TreeView.propTypes = {
  nodes: PropTypes.arrayOf(treeNodeShape.isRequired),
  children: PropTypes.node,
  showFilter: PropTypes.bool,
  renderFilter: PropTypes.func,
  filterProps: PropTypes.shape(treeViewFilterProps),
  filterCallback: PropTypes.func,
  onClick: PropTypes.func,
  defaultParentIcon: PropTypes.string,
  defaultEndIcon: PropTypes.string,
  defaultRenderIcon: PropTypes.func,
  defaultRenderExpandIcon: PropTypes.func,
  defaultRenderChildren: PropTypes.func,
  defaultRenderHeader: PropTypes.func,
  className: PropTypes.string,
  renderNodes: PropTypes.func,
  hideCollapseAll: PropTypes.bool,
  renderCollapseAll: PropTypes.func
}, TreeView.__docgenInfo = {
  description: "@metadataType container",
  methods: [],
  displayName: "TreeView",
  props: {
    nodes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "treeNodeShape.isRequired"
        }
      },
      required: !1,
      description: "Tree view configuration object. Either nodes or children is required."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Tree view configuration node. Either nodes or children is required."
    },
    showFilter: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Is filter input visible"
    },
    renderFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render custom filter component"
    },
    filterProps: {
      type: {
        name: "shape",
        value: "import { TreeViewFilter, treeViewFilterProps } from './TreeViewFilter';",
        computed: !0
      },
      required: !1,
      description: "Props passed to filter input"
    },
    filterCallback: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom comparison function"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default callback for click events on nodes"
    },
    defaultParentIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default TreeNode's icon name to be rendered when node is a parent - has children"
    },
    defaultEndIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default TreeNode's icon name to be rendered when node is an end node - has no children"
    },
    defaultRenderIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's icon render function or component"
    },
    defaultRenderExpandIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's expand icon render function or component"
    },
    defaultRenderChildren: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's children render function"
    },
    defaultRenderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's header render function or component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional classname to style tree view"
    },
    renderNodes: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node's nodes render function. Used only when using nodes config."
    },
    hideCollapseAll: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide 'Collapse/Expand all' button"
    },
    renderCollapseAll: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render custom 'Collapse/Expand all' button"
    }
  }
}, defaultRenderFilter.__docgenInfo = {
  componentName: "defaultRenderFilter",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultRenderFilter",
  methods: [],
  actualName: "defaultRenderFilter"
}, TreeView.__docgenInfo = {
  componentName: "TreeView",
  packageName: "@jutro/components",
  description: "",
  displayName: "TreeView",
  methods: [],
  actualName: "TreeView",
  metadataType: "container",
  props: {
    nodes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "treeNodeShape"
        }
      },
      required: !1,
      description: "Tree view configuration object. Either nodes or children is required."
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "Tree view configuration node. Either nodes or children is required."
    },
    showFilter: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Is filter input visible"
    },
    renderFilter: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render custom filter component"
    },
    filterProps: {
      type: {
        name: "shape",
        value: "import { TreeViewFilter, treeViewFilterProps } from './TreeViewFilter';",
        computed: !0
      },
      required: !1,
      description: "Props passed to filter input"
    },
    filterCallback: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Custom comparison function"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default callback for click events on nodes"
    },
    defaultParentIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default TreeNode's icon name to be rendered when node is a parent - has children"
    },
    defaultEndIcon: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Default TreeNode's icon name to be rendered when node is an end node - has no children"
    },
    defaultRenderIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's icon render function or component"
    },
    defaultRenderExpandIcon: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's expand icon render function or component"
    },
    defaultRenderChildren: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's children render function"
    },
    defaultRenderHeader: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Default TreeNode's header render function or component"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "Additional classname to style tree view"
    },
    renderNodes: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Node's nodes render function. Used only when using nodes config."
    },
    hideCollapseAll: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "Hide 'Collapse/Expand all' button"
    },
    renderCollapseAll: {
      type: {
        name: "func"
      },
      required: !1,
      description: "Render custom 'Collapse/Expand all' button"
    }
  }
};