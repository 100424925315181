import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import isString from "lodash/isString";
import set from "lodash/set";
export function navigateTo(instance, path, result) {
  if (instance && path && !function (instance, path) {
    var loc = instance.props.location;
    if ("object" === _typeof(path)) {
      var pathname = path.pathname,
        _path$search = path.search,
        search = void 0 === _path$search ? "" : _path$search,
        _path$hash = path.hash,
        hash = void 0 === _path$hash ? "" : _path$hash;
      return pathname === loc.pathname && search === loc.search && hash === loc.hash;
    }
    return path === loc.pathname;
  }(instance, path)) {
    var location = path;
    isString(location) ? location = {
      pathname: path,
      state: {
        action: "redirect",
        result: result
      }
    } : result && set(location, "state.result", result);
    var _instance$props = instance.props,
      history = _instance$props.history;
    _instance$props.replace ? history.replace(location) : history.push(location);
  }
}