import "core-js/modules/es.regexp.exec";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _regeneratorRuntime from "@babel/runtime-corejs3/regenerator";
import _parseInt from "@babel/runtime-corejs3/core-js-stable/parse-int";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import { closestTo } from "date-fns";
import { DATA_TYPE_OBJECT, DATA_TYPE_STRING, DATA_TYPE_NUMBER } from "@jutro/prop-types";
export function getYearFromValue(value) {
  if (value) {
    if (value instanceof Date) return value;
    var yearValue = value;
    "object" === _typeof(value) && "year" in value && (yearValue = value.year);
    var date = new Date(Number(yearValue), 1, 1);
    return Number.isNaN(date.getTime()) ? void 0 : date;
  }
}
export function yearValueToDataType(value, dataType) {
  if (value) {
    var valueAsNumber = _parseInt(value, 10);
    switch (dataType) {
      case DATA_TYPE_STRING:
        return value;
      case DATA_TYPE_NUMBER:
        return valueAsNumber;
      case DATA_TYPE_OBJECT:
        return {
          year: valueAsNumber
        };
      default:
        return value;
    }
  }
}
export function twoToFourDigitYear(inputValue, minYear, maxYear) {
  var _context5,
    _marked = _regeneratorRuntime.mark(generateYearPermutations);
  if (2 !== (null == inputValue ? void 0 : inputValue.length)) return inputValue;
  function generateYearPermutations(min, max, decade) {
    var minCentury, maxCentury, currentCentury, _context, _context2, minYearRequirement, maxYearRequirement, _context3;
    return _regeneratorRuntime.wrap(function (_context4) {
      for (;;) switch (_context4.prev = _context4.next) {
        case 0:
          minCentury = min ? _parseInt(String(min).substring(0, 2), 10) : 19, maxCentury = max ? _parseInt(String(max).substring(0, 2), 10) : 20, currentCentury = minCentury;
        case 3:
          if (!(currentCentury <= maxCentury)) {
            _context4.next = 12;
            break;
          }
          if (minYearRequirement = !min || _parseInt(_concatInstanceProperty(_context = "".concat(currentCentury)).call(_context, decade), 10) >= min, maxYearRequirement = !max || _parseInt(_concatInstanceProperty(_context2 = "".concat(currentCentury)).call(_context2, decade), 10) <= max, !minYearRequirement || !maxYearRequirement) {
            _context4.next = 9;
            break;
          }
          return _context4.next = 9, _concatInstanceProperty(_context3 = "".concat(currentCentury)).call(_context3, decade);
        case 9:
          currentCentury += 1, _context4.next = 3;
          break;
        case 12:
        case "end":
          return _context4.stop();
      }
    }, _marked);
  }
  var dates = _mapInstanceProperty(_context5 = _Array$from(generateYearPermutations(_parseInt(String(minYear), 10), _parseInt(String(maxYear), 10), inputValue))).call(_context5, function (year) {
      return new Date(_parseInt(year, 10), 1, 0);
    }),
    closest = closestTo(new Date(), dates);
  return closest ? String(closest.getFullYear()) : "";
}
export function getInputFormat(inputFormat) {
  return Array.isArray(inputFormat) ? inputFormat[0] : inputFormat;
}
export function isValidYear() {
  var val = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
    length = arguments.length > 1 ? arguments[1] : void 0,
    regexp = /[1-9][0-9]{3}/;
  return 2 === length && (regexp = /[0-9]{2}/), regexp.test(val);
}
export function isNumberKey(evt) {
  var charCode = evt.which || evt.keyCode;
  charCode > 31 && (charCode < 48 || charCode > 57) && evt.preventDefault();
}