import "core-js/modules/es.regexp.exec";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import camelCase from "lodash/camelCase";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
function appendClassName(inputClassName, className) {
  var _context;
  return className ? inputClassName ? _concatInstanceProperty(_context = "".concat(inputClassName, " ")).call(_context, className) : className : inputClassName;
}
export function parseThemeConfig(config) {
  if (config) {
    var classNamePrefix = config.prefix || camelCase(config.name),
      isDynamic = isNil(config.prefix) || "" === config.prefix,
      parsedOutput = {
        classNames: {},
        styles: []
      };
    return function (rootConfig, classNamePrefix, isDynamic, output) {
      var _context2;
      rootConfig instanceof Object ? (output.classNames.root = appendClassName(output.classNames.root, "".concat(classNamePrefix, "Root")), isDynamic && (output.classNames.root = appendClassName(output.classNames.root, "dynamicRoot")), output.styles.push({
        selector: _mapInstanceProperty(_context2 = output.classNames.root.split(" ")).call(_context2, function (className) {
          return ".".concat(className);
        }).join(""),
        properties: rootConfig
      })) : output.classNames.root = rootConfig;
    }(config.rootStyle, classNamePrefix, isDynamic, parsedOutput), omitBy(parsedOutput, isEmpty);
  }
}