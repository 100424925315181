import "core-js/modules/es.regexp.exec";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime-corejs3/helpers/typeof";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
var _valueParserMap,
  _excluded = ["placeholder"],
  _excluded2 = ["placeholder", "value"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FormattedNumber as IntlFormattedNumber } from "react-intl";
import { isEmptyValue } from "@jutro/data";
import { DATA_TYPE_OBJECT, DATA_TYPE_STRING, DATA_TYPE_NUMBER, DATA_TYPE_GW_REST_STRING, DATA_TYPE_GW_REST_OBJECT, currencyValueShape, intlMessageShape } from "@jutro/prop-types";
import { useTranslator, CurrencyContext, getDefaultCurrency } from "@jutro/locale";
import { FieldComponent } from "../inputs/FieldComponent/FieldComponent";
export var valueParserMap = (_valueParserMap = {}, _defineProperty(_valueParserMap, DATA_TYPE_OBJECT, {
  getValue: function (value) {
    return value && !isEmptyValue(value.amount) ? value.amount : "";
  },
  getCurrency: function (value, defaultCurrency) {
    return value && value.currency || defaultCurrency;
  },
  newValue: function (amount, currency) {
    return {
      amount: amount,
      currency: currency
    };
  }
}), _defineProperty(_valueParserMap, DATA_TYPE_STRING, {
  getValue: function (value) {
    return getAmountFromString(value);
  },
  getCurrency: function (value, defaultCurrency) {
    return getCurrencyFromString(value) || defaultCurrency;
  },
  newValue: function (amount, currency) {
    var _context;
    return _concatInstanceProperty(_context = "".concat(isEmptyValue(amount) ? "" : amount, " ")).call(_context, currency);
  }
}), _defineProperty(_valueParserMap, DATA_TYPE_NUMBER, {
  getValue: function (value) {
    return isEmptyValue(value) ? null : value;
  },
  getCurrency: function (value, defaultCurrency) {
    return defaultCurrency;
  },
  newValue: function (amount) {
    return amount;
  }
}), _defineProperty(_valueParserMap, DATA_TYPE_GW_REST_STRING, {
  getValue: function (value) {
    return value;
  },
  getCurrency: function (_, defaultCurrency) {
    return defaultCurrency;
  },
  newValue: function (amount) {
    return "".concat(isEmptyValue(amount) ? "" : amount);
  }
}), _defineProperty(_valueParserMap, DATA_TYPE_GW_REST_OBJECT, {
  getValue: function (value) {
    return value && !isEmptyValue(value.amount) ? value.amount : "";
  },
  getCurrency: function (value, defaultCurrency) {
    return value && value.currency || defaultCurrency;
  },
  newValue: function (amount, currency) {
    return isEmptyValue(amount) ? void 0 : {
      amount: "".concat(amount),
      currency: currency
    };
  }
}), _valueParserMap);
var splitValue = function (value) {
    return value && value.split && value.split(/\s+/) || [];
  },
  getAmountFromString = function (value) {
    return splitValue(value)[0];
  },
  getCurrencyFromString = function (value) {
    return splitValue(value)[1];
  };
function getFormattedCurrencyProps(props, currencyObject, currencyContext) {
  var placeholder = props.placeholder,
    currencyDisplay = props.currencyDisplay,
    showFractions = props.showFractions,
    showGrouping = props.showGrouping,
    value = props.value,
    amountProp = props.amount,
    currencyProp = props.currency,
    _parseValue = function (value, defaultAmount, defaultCurrency) {
      var amount = defaultAmount,
        currency = defaultCurrency;
      if (value) {
        var dataType = _typeof(value);
        amount = valueParserMap[dataType].getValue(value), currency = valueParserMap[dataType].getCurrency(value, defaultCurrency);
      }
      return {
        amount: amount,
        currency: currency
      };
    }(currencyObject || value, (null == currencyObject ? void 0 : currencyObject.amount) || amountProp, (null == currencyObject ? void 0 : currencyObject.currency) || currencyProp || (null == currencyContext ? void 0 : currencyContext.defaultCurrency)),
    amount = _parseValue.amount,
    currency = _parseValue.currency;
  return !isEmptyValue(amount) ? {
    value: amount,
    style: "currency",
    currency: currency,
    currencyDisplay: currencyDisplay,
    useGrouping: showGrouping,
    minimumFractionDigits: showFractions ? void 0 : 0,
    maximumFractionDigits: showFractions ? void 0 : 0
  } : {
    placeholder: null != placeholder ? placeholder : FieldComponent.defaultROEmptyValue
  };
}
var formattedCurrencyPropTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.string,
  currencyDisplay: PropTypes.oneOf(["code", "symbol", "name"]),
  showFractions: PropTypes.bool,
  showGrouping: PropTypes.bool,
  value: currencyValueShape,
  placeholder: intlMessageShape
};
export var FormattedCurrency = function (props) {
  var _getFormattedCurrency = getFormattedCurrencyProps(props, void 0, useContext(CurrencyContext)),
    placeholder = _getFormattedCurrency.placeholder,
    formattedCurrencyProps = _objectWithoutProperties(_getFormattedCurrency, _excluded),
    translator = useTranslator();
  return placeholder ? React.createElement(React.Fragment, null, translator(placeholder)) : React.createElement(IntlFormattedNumber, formattedCurrencyProps);
};
FormattedCurrency.propTypes = formattedCurrencyPropTypes, FormattedCurrency.defaultProps = {
  currencyDisplay: "symbol",
  placeholder: FieldComponent.defaultROEmptyValue,
  showGrouping: !0
};
export function formatCurrency(intl, props, currencyObject) {
  var _getFormattedCurrency2 = getFormattedCurrencyProps(props, currencyObject, {
      defaultCurrency: getDefaultCurrency()
    }),
    placeholder = _getFormattedCurrency2.placeholder,
    value = _getFormattedCurrency2.value,
    formattedCurrencyProps = _objectWithoutProperties(_getFormattedCurrency2, _excluded2);
  return placeholder || intl.formatNumber(value, formattedCurrencyProps);
}
FormattedCurrency.__docgenInfo = {
  description: "The `FormattedCurrency` component is used to render a formatted currency value.\n\n@type {React.FC<PropTypes.InferProps<typeof formattedCurrencyPropTypes>>}\n\n@metadataType element",
  methods: [],
  displayName: "FormattedCurrency",
  props: {
    currencyDisplay: {
      defaultValue: {
        value: "'symbol'",
        computed: !1
      },
      type: {
        name: "enum",
        value: [{
          value: "'code'",
          computed: !1
        }, {
          value: "'symbol'",
          computed: !1
        }, {
          value: "'name'",
          computed: !1
        }]
      },
      required: !1,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'"
    },
    placeholder: {
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      },
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The string to display in the UI if 'amount' is undefined/null"
    },
    showGrouping: {
      defaultValue: {
        value: "true",
        computed: !1
      },
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays grouping separators with the value"
    },
    amount: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "The numeric value to display"
    },
    currency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The currency name to display for localization"
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays fractions with the amount"
    },
    value: {
      type: {
        name: "custom",
        raw: "currencyValueShape"
      },
      required: !1,
      description: "Value to display in the format of string|number|object"
    }
  }
}, FormattedCurrency.__docgenInfo = {
  componentName: "FormattedCurrency",
  packageName: "@jutro/components",
  description: "The `FormattedCurrency` component is used to render a formatted currency value.",
  displayName: "FormattedCurrency",
  methods: [],
  actualName: "FormattedCurrency",
  metadataType: "element",
  props: {
    amount: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: !1,
      description: "The numeric value to display"
    },
    currency: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The currency name to display for localization"
    },
    currencyDisplay: {
      type: {
        name: "enum",
        value: [{
          value: "'code'",
          computed: !1
        }, {
          value: "'symbol'",
          computed: !1
        }, {
          value: "'name'",
          computed: !1
        }]
      },
      required: !1,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'",
      defaultValue: {
        value: "'symbol'",
        computed: !1
      }
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays fractions with the amount"
    },
    showGrouping: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "If true, displays grouping separators with the value",
      defaultValue: {
        value: "true",
        computed: !1
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: !1
            },
            currency: {
              name: "string",
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "Value to display in the format of string|number|object"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The string to display in the UI if 'amount' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: !0
      }
    }
  }
};