import "core-js/modules/es.regexp.exec";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _parseInt from "@babel/runtime-corejs3/core-js-stable/parse-int";
import _padStartInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/pad-start";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import isString from "lodash/isString";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import defaultLocaleObject from "date-fns/locale/en-US";
import { parseDateFromDataType, inputFormatToValueParts, getDatePatternFromLocale } from "@jutro/locale";
import { twoToFourDigitYear } from "../YearField/helpers";
export var DEFAULT_INPUT_FORMAT = "MM/dd/yyyy";
export function isNumberKey(evt) {
  var charCode = evt.which || evt.keyCode;
  charCode > 31 && (charCode < 48 || charCode > 57) && evt.preventDefault();
}
export function toInt(value) {
  return isString(value) ? _parseInt(value, 10) : value;
}
export function toZeroIndexedMonth(value) {
  return isString(value) ? _parseInt(value, 10) - 1 : value - 1;
}
export function toOneIndexedMonth(value) {
  return isString(value) ? _parseInt(value, 10) + 1 : value + 1;
}
export function toLocaleFormat(value, options) {
  var _options$localeFormat,
    _context,
    formatLength = null == options || null === (_options$localeFormat = options.localeFormat) || void 0 === _options$localeFormat ? void 0 : _options$localeFormat.length;
  return _padStartInstanceProperty(_context = String(value)).call(_context, formatLength || 0, "0");
}
export function toTwoDigitYear(value, options) {
  var _context2;
  return "yy" === (null == options ? void 0 : options.yearFormat) ? _sliceInstanceProperty(_context2 = String(value)).call(_context2, -2) : value;
}
export function toFourDigitYear(value, options) {
  if (!("yy" === (null == options ? void 0 : options.yearFormat))) return value;
  var minYear = (null == options ? void 0 : options.minDate) && parseDateFromDataType(options.minDate).getFullYear(),
    maxYear = (null == options ? void 0 : options.maxDate) && parseDateFromDataType(options.maxDate).getFullYear();
  return twoToFourDigitYear(String(value), minYear, maxYear);
}
export function extractFieldFormat(fieldType, inputFormat, yearFormat) {
  var _context3,
    _context4,
    _context5,
    _filter = _filterInstanceProperty(_context3 = inputFormat.match(/[d]*/gi) || []).call(_context3, Boolean),
    day = _slicedToArray(_filter, 1)[0],
    _filter3 = _filterInstanceProperty(_context4 = inputFormat.match(/[m]*/gi) || []).call(_context4, Boolean),
    month = _slicedToArray(_filter3, 1)[0],
    _filter5 = _filterInstanceProperty(_context5 = inputFormat.match(/[y]*/gi) || []).call(_context5, Boolean),
    year = _slicedToArray(_filter5, 1)[0];
  return {
    day: day,
    month: month,
    year: yearFormat || year
  }[fieldType];
}
export function applyFormatters(formatters, fieldValue, options) {
  if (!isNil(fieldValue) && "" !== fieldValue) return _reduceInstanceProperty(formatters).call(formatters, function (accumulator, formatter) {
    return formatter(accumulator, options);
  }, fieldValue);
}
export function isValidDateObject(value, inputFormat) {
  return inputFormatToValueParts(inputFormat).every(function (fieldType) {
    return !isNil(value[fieldType]);
  });
}
export function extendDateWithDefaults(value) {
  var day = value.day,
    month = value.month,
    year = value.year;
  return {
    day: isNil(day) ? 1 : day,
    month: isNil(month) ? 0 : month,
    year: isNil(year) ? new Date().getFullYear() : year
  };
}
export function trimInputFormat(inputFormat, fieldParts) {
  var _context6,
    valueParts = inputFormatToValueParts(inputFormat);
  return _mapInstanceProperty(_context6 = _filterInstanceProperty(valueParts).call(valueParts, function (valuePart) {
    return _includesInstanceProperty(fieldParts).call(fieldParts, valuePart);
  })).call(_context6, function (valuePart) {
    return extractFieldFormat(valuePart, inputFormat);
  }).join("/");
}
export function getInputFormat(availableFields, dateLocale, userInputFormat) {
  var datePattern = getDatePatternFromLocale(isEmpty(dateLocale) ? defaultLocaleObject : dateLocale);
  if (Array.isArray(userInputFormat)) {
    var _userInputFormat = _slicedToArray(userInputFormat, 2),
      format = _userInputFormat[0],
      message = _userInputFormat[1];
    return [trimInputFormat(format || datePattern, availableFields), message];
  }
  return trimInputFormat(userInputFormat || datePattern, availableFields);
}