import "core-js/modules/es.regexp.exec";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import _getIteratorMethod from "@babel/runtime-corejs3/core-js/get-iterator-method";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = void 0 !== _Symbol && _getIteratorMethod(o) || o["@@iterator"];
  if (!it) {
    if (Array.isArray(o) || (it = function (o, minLen) {
      var _context4;
      if (!o) return;
      if ("string" == typeof o) return _arrayLikeToArray(o, minLen);
      var n = _sliceInstanceProperty(_context4 = Object.prototype.toString.call(o)).call(_context4, 8, -1);
      "Object" === n && o.constructor && (n = o.constructor.name);
      if ("Map" === n || "Set" === n) return _Array$from(o);
      if ("Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
    }(o)) || allowArrayLike && o && "number" == typeof o.length) {
      it && (o = it);
      var i = 0,
        F = function () {};
      return {
        s: F,
        n: function () {
          return i >= o.length ? {
            done: !0
          } : {
            done: !1,
            value: o[i++]
          };
        },
        e: function (_e) {
          throw _e;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var err,
    normalCompletion = !0,
    didErr = !1;
  return {
    s: function () {
      it = it.call(o);
    },
    n: function () {
      var step = it.next();
      return normalCompletion = step.done, step;
    },
    e: function (_e2) {
      didErr = !0, err = _e2;
    },
    f: function () {
      try {
        normalCompletion || null == it.return || it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}
function _arrayLikeToArray(arr, len) {
  (null == len || len > arr.length) && (len = arr.length);
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
import _flatMapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat-map";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$fromEntries from "@babel/runtime-corejs3/core-js-stable/object/from-entries";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import { useCallback, useMemo } from "react";
import get from "lodash/get";
export var getListOfPaths = function getListOfPaths(uiProps) {
  var _context;
  return Array.isArray(uiProps.content) ? _flatMapInstanceProperty(_context = uiProps.content).call(_context, function (item) {
    return Array.isArray(item.content) ? getListOfPaths(item) : get(item, ["componentProps", "path"], []);
  }) : [];
};
var getMetadataValueByPath = function getMetadataValueByPath(uiProps, path, metadataKey) {
    if (Array.isArray(uiProps.content)) {
      var _step,
        _iterator = _createForOfIteratorHelper(uiProps.content);
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (Array.isArray(item.content)) return getMetadataValueByPath(item, path, metadataKey);
          if (get(item, ["componentProps", "path"]) === path) return get(item, metadataKey, path);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
    return path;
  },
  getMessagesWithId = function (messages, fieldId) {
    return _mapInstanceProperty(messages).call(messages, function (val) {
      return _objectSpread(_objectSpread({}, val), {}, {
        fieldId: fieldId
      });
    });
  };
export var useGetLabelMap = function (uiProps, translator) {
  var labelsMapWithIds = useMemo(function () {
      var _context2;
      return _reduceInstanceProperty(_context2 = getListOfPaths(uiProps)).call(_context2, function (acc, path) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, path, {
          label: translator(getMetadataValueByPath(uiProps, path, ["componentProps", "label"])),
          fieldId: translator(getMetadataValueByPath(uiProps, path, "id"))
        }));
      }, {});
    }, [translator, uiProps]),
    getMessagesWithLabelsMap = useCallback(function () {
      var _context3,
        type = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      return _Object$fromEntries(_mapInstanceProperty(_context3 = _Object$entries(type)).call(_context3, function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          path = _ref2[0],
          val = _ref2[1];
        return [labelsMapWithIds[path].label, getMessagesWithId(val, labelsMapWithIds[path].fieldId)];
      }));
    }, [labelsMapWithIds]);
  return getMessagesWithLabelsMap;
};