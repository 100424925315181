import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import { warning } from "@jutro/logger";
export default function loadMixpanel(trackingId) {
  return trackingId ? (function (e, a) {
    if (!a.__SV) {
      var k,
        h,
        b = window;
      try {
        var c,
          l,
          i,
          j = b.location,
          g = j.hash;
        c = function (a, b) {
          return (l = a.match(RegExp(b + "=([^&]*)"))) ? l[1] : null;
        }, g && c(g, "state") && "mpeditor" === (i = JSON.parse(decodeURIComponent(c(g, "state")))).action && (b.sessionStorage.setItem("_mpcehash", g), history.replaceState(i.desiredHash || "", e.title, j.pathname + j.search));
      } catch (m) {}
      window.mixpanel = a, a._i = [], a.init = function (b, c, f) {
        function e(b, a) {
          var c = a.split(".");
          2 == c.length && (b = b[c[0]], a = c[1]), b[a] = function () {
            var _context;
            b.push(_concatInstanceProperty(_context = [a]).call(_context, _sliceInstanceProperty(Array.prototype).call(arguments, 0)));
          };
        }
        var d = a;
        for (void 0 !== f ? d = a[f] = [] : f = "mixpanel", d.people = d.people || [], d.toString = function (b) {
          var a = "mixpanel";
          return "mixpanel" !== f && (a += "." + f), b || (a += " (stub)"), a;
        }, d.people.toString = function () {
          return d.toString(1) + ".people (stub)";
        }, k = "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" "), h = 0; h < k.length; h++) e(d, k[h]);
        a._i.push([b, c, f]);
      }, a.__SV = 1.2, (b = e.createElement("script")).type = "text/javascript", b.async = !0, b.src = "undefined" != typeof MIXPANEL_CUSTOM_LIB_URL ? MIXPANEL_CUSTOM_LIB_URL : "file:" === e.location.protocol && "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//) ? "https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js" : "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js", (c = e.getElementsByTagName("script")[0]).parentNode.insertBefore(b, c);
    }
  }(document, window.mixpanel || []), window.mixpanel.init(trackingId), window.mixpanel) : (warning("To enable Mixpanel provide tracking id"), null);
}