import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';

import metadata from './PMPersonDetailsComponent.metadata.json5';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

function PMPersonDetailsComponent(props) {
    const {
        data: personVM,
        onValueChange: writeValue,
        path: dataPath,
        id,
        index,
        showOptional,
        labelPosition,
        onValidate,
        phoneWide,
        minimumAllowedDateOfBirth,
        maximumAllowedDateOfBirth,
        ukResidentMonthIndex,
        handleGenderValueChange,
        onNameValueChange,
        handleUkResidentLessThan5YearsValueChange,
        dateForUkResidentLessThan5Years,
        submitted,
        handleDOBValueChange,
        isFromAMP
    } = props;
    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];
    const GenderFieldavailableValues = [
        { code: 'M', displayName: 'Male' },
        { code: 'F', displayName: 'Female' },
    ];
    const translator = useTranslator();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation('PMPersonDetailsComponent');
    const [invalidDateForUkResident, setInvalidDateForUkResident] = useState(false);
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);
    const onhandleGenderValueChange = (value, path) => {
        const fullPath = `${dataPath}.${path}`;
        handleGenderValueChange(value, fullPath);
    };

    const currentDate = new Date();
    // eslint-disable-next-line max-len
    const fiveYearsAgo = new Date(currentDate.getFullYear() - 5, currentDate.getMonth(), currentDate.getDay());

    const onUKResidentValueChange = (value, path) => {
        let isValidDate = false;

        if (value.year == currentDate.getFullYear()) {
            if (value.month <= currentDate.getMonth()) {
                isValidDate = true;
            }
        } else if (value.year > fiveYearsAgo.getFullYear()) {
            isValidDate = true;
        } else if (value.year == fiveYearsAgo.getFullYear()) {
            if (value.month >= fiveYearsAgo.getMonth()) {
                isValidDate = true;
            }
        }

        setInvalidDateForUkResident(!isValidDate);

        const fullPath = `${dataPath}.${path}`;
        handleUkResidentLessThan5YearsValueChange(value, fullPath);
    };
    const handleOnValueChange = (value, path) => {
        const fullPath = `${dataPath}.${path}`;
        writeValue(value, fullPath);
    };
    const onNameHandleValueChange = (value, path) => {
        const fullPath = `${dataPath}.${path}`;
        onNameValueChange(value, fullPath);
    };
    const onHandleDOBValueChange = (value, path) => {
        handleDOBValueChange(value, `${dataPath}.${path}`);
    };
    const getBooleanFieldValue = (fieldPath) => {
        const booleanVal = _.get(personVM, fieldPath, false);

        return booleanVal;
    };
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'PMPersonDetailsComponent');
        }
    }, [id, onValidate, isComponentValid]);

    const overrideProps = {
        '@field': {
            phoneWide,
            readOnly: isFromAMP,
        },
        [`title${index}`]: {
            required: getBooleanFieldValue('title_itb.aspects.required'),
            showRequired: getBooleanFieldValue('title_itb.aspects.required'),
            availableValues: (typeof personVM !== 'undefined' && personVM && Object.keys(personVM).length > 0)
                ? personVM.title_itb.aspects.availableValues[0].typelist.getFilter('TYAorKFI').codes.map((typeCode) => ({
                    code: typeCode.code,
                    name: translator({
                        id: typeCode.name,
                        defaultMessage: typeCode.name
                    })
                }))
                : [],
            validationMessages: (personVM.title_itb && personVM.title_itb.value === undefined && submitted) ? ['This field is required'] : []
        },
        [`firstName${index}`]: {
            onValueChange: onNameHandleValueChange,
            validationMessages: (personVM.firstName && (personVM.firstName.value == '' || personVM.firstName.value == null)) ? ['This field is required'] : [],
            required: getBooleanFieldValue('firstName.aspects.required'),
            showRequired: getBooleanFieldValue('firstName.aspects.required')
        },
        [`lastName${index}`]: {
            onValueChange: onNameHandleValueChange,
            validationMessages: (personVM.lastName && (personVM.lastName.value == '' || personVM.lastName.value == null)) ? ['This field is required'] : [],
            required: getBooleanFieldValue('lastName.aspects.required'),
            showRequired: getBooleanFieldValue('lastName.aspects.required')
        },
        [`ukresident${index}`]: {
            availableValues: YESNOFieldavailableValues,
            required: getBooleanFieldValue('ukResident5Years_itb.aspects.required'),
            showRequired: getBooleanFieldValue('ukResident5Years_itb.aspects.required')
        },
        [`ukresidentLessthan5Years${index}`]: {
            visible: _.get(personVM, 'ukResident5Years_itb.value') === false,
            value: dateForUkResidentLessThan5Years,
            maxDate: {
                year: currentDate.getFullYear(),
                month: currentDate.getMonth()
            },
            minDate: {
                year: fiveYearsAgo.getFullYear() - 1,
                month: fiveYearsAgo.getMonth(),
                day: fiveYearsAgo.getDay()
            },
            required: getBooleanFieldValue('ukResidencyMonth_itb.aspects.required') || getBooleanFieldValue('ukResidencyYear_itb.aspects.required'),
            showRequired: getBooleanFieldValue('ukResidencyMonth_itb.aspects.required') || getBooleanFieldValue('ukResidencyYear_itb.aspects.required'),
            onValueChange: onUKResidentValueChange,
            // eslint-disable-next-line no-nested-ternary
            validationMessages: (dateForUkResidentLessThan5Years === undefined && submitted) ? ['Please fill out this field'] : (invalidDateForUkResident ? ['Invalid Date'] : [])
        },
        [`dateOfBirth${index}`]: {
            maxDate: minimumAllowedDateOfBirth,
            minDate: {
                year: maximumAllowedDateOfBirth.getFullYear(),
                month: maximumAllowedDateOfBirth.getMonth(),
                day: maximumAllowedDateOfBirth.getDate() + 1
            },
            validationMessages: (personVM.dateOfBirth && (personVM.dateOfBirth.year?.value === '' || personVM.dateOfBirth.year?.value == null)) ? ['Please fill out this field'] : [],
            onValueChange: onHandleDOBValueChange,
            required: getBooleanFieldValue('dateOfBirth.aspects.required'),
            showRequired: getBooleanFieldValue('dateOfBirth.aspects.required')
        },
        [`gender${index}`]: {
            onValueChange: onhandleGenderValueChange,
            availableValues: GenderFieldavailableValues,
            validationMessages: (personVM.gender_itb && personVM.gender_itb.value === undefined && submitted) ? ['This field is required'] : [],
            required: getBooleanFieldValue('gender_itb.aspects.required'),
            showRequired: getBooleanFieldValue('gender_itb.aspects.required')
        },
        [`martialstatus${index}`]: {
            validationMessages: (personVM.maritalStatus && personVM.maritalStatus.value === undefined && submitted) ? ['Please fill out this field'] : [],
            required: getBooleanFieldValue('maritalStatus.aspects.required'),
            showRequired: getBooleanFieldValue('maritalStatus.aspects.required')
        },
        [`occupationlookup${index}`]: {
            required: getBooleanFieldValue('occupation_itb.aspects.required'),
            showRequired: getBooleanFieldValue('occupation_itb.aspects.required')
        },
        [`occupationStatus${index}`]: {
            required: getBooleanFieldValue('occupationStatus_itb.aspects.required'),
            showRequired: getBooleanFieldValue('occupationStatus_itb.aspects.required')
        },
        [`employersbusinesslookup${index}`]: {
            required: getBooleanFieldValue('employersBusiness_itb.aspects.required'),
            showRequired: getBooleanFieldValue('employersBusiness_itb.aspects.required')
        }
    };

    const resolvers = {
    };
    return (
        <ViewModelForm
            model={personVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
            onValueChange={handleOnValueChange}
            showErrors={submitted}
        />
    );
}
PMPersonDetailsComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMPersonDetailsComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: false,
    id: undefined,
    index: 0
};
export default PMPersonDetailsComponent;
