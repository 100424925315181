import "core-js/modules/es.regexp.exec";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
var _excluded = ["onClick", "to", "history", "children", "location", "match", "loading", "onTrigger"];
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = function () {
    if ("undefined" == typeof Reflect || !_Reflect$construct) return !1;
    if (_Reflect$construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(_Reflect$construct(Boolean, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }();
  return function () {
    var result,
      Super = _getPrototypeOf(Derived);
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else result = Super.apply(this, arguments);
    return _possibleConstructorReturn(this, result);
  };
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import { createLocation } from "history";
import memoize from "memoize-one";
import { Link } from "@jutro/components";
import { intlMessageShape, intlToShape, IntlToShape } from "@jutro/prop-types";
import { metadataTypes } from "@jutro/uimetadata";
import { withAsyncAction } from "../AsyncAction/AsyncAction";
var getHref = function (to, history) {
    if (to) {
      var locationTo = "string" == typeof to ? createLocation(to, null, null, history.location) : to;
      return history.createHref(locationTo);
    }
  },
  isRecalculationNeeded = function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
      to1 = _ref3[0],
      history1 = _ref3[1],
      _ref4 = _slicedToArray(_ref2, 2),
      to2 = _ref4[0],
      history2 = _ref4[1];
    return to1 === to2 && history1.location === history2.location;
  },
  AsyncLinkInternal = function (_Component) {
    _inherits(AsyncLinkInternal, Component);
    var _super = _createSuper(AsyncLinkInternal);
    function AsyncLinkInternal() {
      var _context, _this;
      _classCallCheck(this, AsyncLinkInternal);
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
      return _this = _super.call.apply(_super, _concatInstanceProperty(_context = [this]).call(_context, args)), _defineProperty(_assertThisInitialized(_this), "getHref", memoize(getHref, isRecalculationNeeded)), _this;
    }
    return _createClass(AsyncLinkInternal, [{
      key: "render",
      value: function () {
        var _this$props = this.props,
          onClick = _this$props.onClick,
          to = _this$props.to,
          history = _this$props.history,
          children = _this$props.children,
          rest = (_this$props.location, _this$props.match, _this$props.loading, _this$props.onTrigger, _objectWithoutProperties(_this$props, _excluded));
        return React.createElement(Link, _extends({
          onClick: onClick,
          href: this.getHref(to, history)
        }, rest), children);
      }
    }]), AsyncLinkInternal;
  }();
_defineProperty(AsyncLinkInternal, "propTypes", {
  failTo: intlToShape,
  failToMessage: PropTypes.string,
  message: intlMessageShape,
  replace: PropTypes.bool,
  to: intlToShape,
  toMessage: PropTypes.string,
  onTrigger: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
}), _defineProperty(AsyncLinkInternal, "metadataType", metadataTypes.ACTION), _defineProperty(AsyncLinkInternal, "displayName", "AsyncLink");
export var AsyncLink = withAsyncAction(AsyncLinkInternal);
AsyncLinkInternal.__docgenInfo = {
  description: "AsyncLink is a Jutro component,\ndesigned following the principles of Link (React Router 4).\nAsyncLink invokes the trigger and wait for promise to be resolved or rejected.\nMeanwhile the promise is in execution, the component updates its content within the message provided by property {message}\nCase promise has been resolved, AsyncLink attempts to navigate to destination provided by property {to}\nCase promise has been rejected, AsyncLink attempts to navigate to destination provided by property {failTo}\n\nAsyncLink automatically adds the href for anchor tag having the to property defined; It follows the react-router-4 implementation:\nhttps://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js\n\n@example\nclass MyPage {\n     onTrigger = () => Promise.resolve(true);\n}\n\n render(){\n     // All props supported\n     return (\n         <AsyncLink\n             onTrigger={this.onTrigger}\n             message='jutro-router.AsyncLink.Loading...'\n             to='/dashboard/success'\n             failTo='/dashboard/fail'\n             replace\n         >\n             Click Me!\n         </AsyncLink>\n     )\n }\n}\n\n@metadataType action",
  methods: [],
  displayName: "AsyncLink",
  props: {
    failTo: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is rejected; can be an object like `<Link to>`"
    },
    failToMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is rejected; shown if 'failTo' is not provided"
    },
    message: {
      type: {
        name: "custom",
        raw: "intlMessageShape"
      },
      required: !1,
      description: "The message shown when executing the trigger/promise"
    },
    replace: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The replace prop will replace the current entry in the history stack"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is resolved; can be an object like `<Link to>`"
    },
    toMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is resolved; shown if 'to' is not provided"
    },
    onTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "The method used to trigger the promise"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The method to be called before (instead in case of event.preventDefault been called) the built-in onClick handler"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The children elements to render inside of the AsyncLink"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    }
  }
}, AsyncLinkInternal.__docgenInfo = {
  componentName: "AsyncLink",
  packageName: "@jutro/router",
  description: "AsyncLink is a Jutro component,\ndesigned following the principles of Link (React Router 4).\nAsyncLink invokes the trigger and wait for promise to be resolved or rejected.\nMeanwhile the promise is in execution, the component updates its content within the message provided by property {message}\nCase promise has been resolved, AsyncLink attempts to navigate to destination provided by property {to}\nCase promise has been rejected, AsyncLink attempts to navigate to destination provided by property {failTo}\n\nAsyncLink automatically adds the href for anchor tag having the to property defined; It follows the react-router-4 implementation:\nhttps://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js",
  displayName: "AsyncLink",
  methods: [],
  actualName: "AsyncLinkInternal",
  metadataType: "action",
  props: {
    failTo: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is rejected; can be an object like `<Link to>`"
    },
    failToMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is rejected; shown if 'failTo' is not provided"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: !1
            },
            defaultMessage: {
              name: "string",
              required: !1
            },
            args: {
              name: "shape",
              value: {},
              required: !1
            }
          }
        }]
      },
      required: !1,
      description: "The message shown when executing the trigger/promise"
    },
    replace: {
      type: {
        name: "bool"
      },
      required: !1,
      description: "The replace prop will replace the current entry in the history stack"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: !1,
      description: "The destination path when the promise is resolved; can be an object like `<Link to>`"
    },
    toMessage: {
      type: {
        name: "string"
      },
      required: !1,
      description: "The message shown when the promise is resolved; shown if 'to' is not provided"
    },
    onTrigger: {
      type: {
        name: "func"
      },
      required: !0,
      description: "The method used to trigger the promise"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: !1,
      description: "The method to be called before (instead in case of event.preventDefault been called) the built-in onClick handler"
    },
    children: {
      type: {
        name: "node"
      },
      required: !1,
      description: "The children elements to render inside of the AsyncLink"
    },
    className: {
      type: {
        name: "string"
      },
      required: !1,
      description: "CSS class name for this component"
    }
  }
};