import "core-js/modules/es.regexp.exec";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import stubTrue from "lodash/stubTrue";
import get from "lodash/get";
export var createTextFilter = function (filterValue) {
  var _context,
    words = _filterInstanceProperty(_context = _trimInstanceProperty(filterValue).call(filterValue).toLowerCase().split(" ")).call(_context, Boolean);
  return words.length ? function (text) {
    var lowerText = null == text ? void 0 : text.toString().toLowerCase();
    return words.some(function (word) {
      return null == lowerText ? void 0 : _includesInstanceProperty(lowerText).call(lowerText, word);
    });
  } : stubTrue;
};
export var createStrictTextFilter = function (filterValue) {
  return filterValue ? function (text) {
    var lowerText = null == text ? void 0 : text.toString().toLowerCase();
    return null == lowerText ? void 0 : _includesInstanceProperty(lowerText).call(lowerText, _trimInstanceProperty(filterValue).call(filterValue).toLowerCase());
  } : stubTrue;
};
export var defaultColumnFilter = function (filterValue, _ref, strictSearch) {
  var path = _ref.path,
    textFilter = strictSearch ? createStrictTextFilter(filterValue) : createTextFilter(filterValue);
  return function (item) {
    return textFilter(get(item, path, ""));
  };
};