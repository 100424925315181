import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Object$getOwnPropertyDescriptor from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor";
import _Object$getOwnPropertyDescriptors from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors";
import _Object$defineProperties from "@babel/runtime-corejs3/core-js-stable/object/define-properties";
import _Object$defineProperty from "@babel/runtime-corejs3/core-js-stable/object/define-property";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = _Object$keys(object);
  if (_Object$getOwnPropertySymbols) {
    var symbols = _Object$getOwnPropertySymbols(object);
    enumerableOnly && (symbols = _filterInstanceProperty(symbols).call(symbols, function (sym) {
      return _Object$getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import get from "lodash/get";
import mergeWith from "lodash/mergeWith";
import merge from "lodash/merge";
import isArray from "lodash/isArray";
import substituteParametersValues from "./load-config";
import defaultConfig from "./defaults/defaultConfig.json";
export function loadAndMergeConfiguration(config) {
  var params = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return loadConfiguration(config, params, defaultConfig);
}
export function loadConfiguration() {
  var config = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
    params = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
    baseConfig = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : defaultConfig;
  return baseConfig && (config = mergeWith(baseConfig, config, function (target, src) {
    if (isArray(target) && isArray(src)) return src;
  })), setConfiguration(config = substituteParametersValues(config, params)), config;
}
var CONFIG_DATA_KEY = _Symbol("jutro.config.data");
export default function getConfiguration() {
  return global[CONFIG_DATA_KEY];
}
export function setConfiguration(config) {
  global[CONFIG_DATA_KEY] = function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = null != arguments[i] ? arguments[i] : {};
      i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      }) : _Object$getOwnPropertyDescriptors ? _Object$defineProperties(target, _Object$getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
        _Object$defineProperty(target, key, _Object$getOwnPropertyDescriptor(source, key));
      });
    }
    return target;
  }({}, config);
}
export function resolveValue(value) {
  var parsedValue = function (value) {
    if ("true" === value) return !0;
    if ("false" === value) return !1;
    return value;
  }(value);
  return parsedValue;
}
export function getConfigValue(path, defaultValue) {
  var _envVariable$,
    value = null,
    envVariable = ("undefined" != typeof __FULL_ENV__ ? __FULL_ENV__ : null) || process.env;
  if (value = null !== (_envVariable$ = envVariable["REACT_APP_".concat(path.replace(/\./g, "_"))]) && void 0 !== _envVariable$ ? _envVariable$ : envVariable[path]) return resolveValue(value);
  var config = getConfiguration();
  return void 0 === (value = get(config, path)) ? defaultValue : resolveValue(value);
}
export function getAndExtendConfigValue(path, extension) {
  var configValue = getConfigValue(path);
  return merge(configValue, extension);
}
export function isFeatureEnabled(path) {
  var allEnabled = getConfigValue("feature.all", !0);
  return getConfigValue("feature.".concat(path), allEnabled);
}