import "core-js/modules/es.regexp.exec";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _URLSearchParams from "@babel/runtime-corejs3/core-js-stable/url-search-params";
import _concatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/concat";
import LocaleService, { getDefaultCountryCode as _getDefaultCountryCode, getDefaultCurrency as _getDefaultCurrency, getDefaultTimeZone as _getDefaultTimeZone, LANGUAGE_URL_PARAM, LOCALE_URL_PARAM } from "./LocaleService";
export var LocaleServiceGlobalizationSettings = function () {
  function LocaleServiceGlobalizationSettings(storageOptions) {
    _classCallCheck(this, LocaleServiceGlobalizationSettings), _defineProperty(this, "storageOptions", void 0), this.storageOptions = storageOptions;
  }
  return _createClass(LocaleServiceGlobalizationSettings, [{
    key: "getPreferredLanguage",
    value: function () {
      var _this$storageOptions = this.storageOptions,
        _this$storageOptions$ = _this$storageOptions.skipLocalStoragePersistence,
        skipLocalStoragePersistence = void 0 !== _this$storageOptions$ && _this$storageOptions$,
        _this$storageOptions$2 = _this$storageOptions.skipURLPersistence,
        skipURLPersistence = void 0 !== _this$storageOptions$2 && _this$storageOptions$2;
      return LocaleService.getCurrentLanguage(skipLocalStoragePersistence, skipURLPersistence);
    }
  }, {
    key: "getPreferredLocale",
    value: function () {
      var _this$storageOptions2 = this.storageOptions,
        _this$storageOptions3 = _this$storageOptions2.skipLocalStoragePersistence,
        skipLocalStoragePersistence = void 0 !== _this$storageOptions3 && _this$storageOptions3,
        _this$storageOptions4 = _this$storageOptions2.skipURLPersistence,
        skipURLPersistence = void 0 !== _this$storageOptions4 && _this$storageOptions4;
      return LocaleService.getCurrentLocale(skipLocalStoragePersistence, skipURLPersistence);
    }
  }, {
    key: "getAvailableLanguages",
    value: function () {
      return LocaleService.getAvailableLanguages();
    }
  }, {
    key: "getAvailableLocales",
    value: function () {
      return LocaleService.getAvailableLocales();
    }
  }, {
    key: "getDefaultCurrency",
    value: function () {
      return _getDefaultCurrency() || "USD";
    }
  }, {
    key: "getDefaultCountryCode",
    value: function () {
      return _getDefaultCountryCode() || "US";
    }
  }, {
    key: "getDefaultTimeZone",
    value: function () {
      return _getDefaultTimeZone();
    }
  }, {
    key: "onGlobalizationChange",
    value: function (_ref) {
      var locale = _ref.locale,
        language = _ref.language,
        languageChanged = _ref.languageChanged,
        localeChanged = _ref.localeChanged,
        _this$storageOptions5 = this.storageOptions,
        _this$storageOptions6 = _this$storageOptions5.skipLocalStoragePersistence,
        skipLocalStoragePersistence = void 0 !== _this$storageOptions6 && _this$storageOptions6,
        _this$storageOptions7 = _this$storageOptions5.skipURLPersistence,
        skipURLPersistence = void 0 !== _this$storageOptions7 && _this$storageOptions7;
      if (localeChanged || languageChanged) {
        var _context,
          queryParams = new _URLSearchParams(window.location.search);
        if (localeChanged && locale && (LocaleService.setCurrentLocale(locale, skipLocalStoragePersistence), queryParams.set(LOCALE_URL_PARAM, locale)), languageChanged && language && (LocaleService.setCurrentLanguage(language, skipLocalStoragePersistence), queryParams.set(LANGUAGE_URL_PARAM, language)), !skipURLPersistence) window.history.replaceState(null, "", _concatInstanceProperty(_context = "".concat(window.location.pathname, "?")).call(_context, queryParams)), window.location.reload();
      }
    }
  }]), LocaleServiceGlobalizationSettings;
}();